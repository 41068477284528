/* Options:
Date: 2024-10-14 08:21:47
Version: 8.0
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5000

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId?: string;
}

export interface IHasBearerToken
{
    bearerToken?: string;
}

export interface IPost
{
}

export interface IPut
{
}

export enum DeliveryStatus
{
    NotSpecified = 'NotSpecified',
    Pending = 'Pending',
    Forwarded = 'Forwarded',
    Dispatched = 'Dispatched',
    Delivered = 'Delivered',
    Failed = 'Failed',
    Undelivered = 'Undelivered',
    Unknown = 'Unknown',
}

export class MessageSmsStatusUpdateDto
{
    public id?: string;
    public notificationStatus: DeliveryStatus;
    public notificationStatusLastUpdate: string;

    public constructor(init?: Partial<MessageSmsStatusUpdateDto>) { (Object as any).assign(this, init); }
}

export class CustomerResponseNotification
{
    public claimId?: string;

    public constructor(init?: Partial<CustomerResponseNotification>) { (Object as any).assign(this, init); }
}

export class SmsStatusUpdate
{
    public claimId?: string;
    public messageId?: string;
    public status: DeliveryStatus;

    public constructor(init?: Partial<SmsStatusUpdate>) { (Object as any).assign(this, init); }
}

export enum IdentityType
{
    Customer = 'Customer',
    Gvb = 'Gvb',
    Craftsman = 'Craftsman',
}

export class IdentityDto
{
    public userId?: string;
    public firstName?: string;
    public lastName?: string;
    public username?: string;
    public phoneNumber?: string;
    public email?: string;
    public identityType: IdentityType;

    public constructor(init?: Partial<IdentityDto>) { (Object as any).assign(this, init); }
}

export class ClaimRegistrationAssignmentChangedDto
{
    public id?: string;
    public newAssignee?: IdentityDto;

    public constructor(init?: Partial<ClaimRegistrationAssignmentChangedDto>) { (Object as any).assign(this, init); }
}

export class ClaimAssignmentChangedDto
{
    public ids?: string[];
    public backOfficeAssignee?: IdentityDto;
    public estimationExpertAssignee?: IdentityDto;

    public constructor(init?: Partial<ClaimAssignmentChangedDto>) { (Object as any).assign(this, init); }
}

export class CraftsmanJobEmailErrorDto
{
    public messageId?: string;
    public message?: string;
    public emailType?: string;

    public constructor(init?: Partial<CraftsmanJobEmailErrorDto>) { (Object as any).assign(this, init); }
}

export class CraftsmanJobEmailStatusUpdate
{
    public claimId?: string;
    public craftsmanJobEmailError?: CraftsmanJobEmailErrorDto;

    public constructor(init?: Partial<CraftsmanJobEmailStatusUpdate>) { (Object as any).assign(this, init); }
}

export enum ClaimMessageChannel
{
    NotSpecified = 'NotSpecified',
    None = 'None',
    Sms = 'Sms',
    Email = 'Email',
    Letter = 'Letter',
}

export enum RepairWorkState
{
    NotSpecified = 'NotSpecified',
    YesWithRepairs = 'YesWithRepairs',
    OffersRequested = 'OffersRequested',
    NothingPlanned = 'NothingPlanned',
}

export enum CustomerType
{
    NotSpecified = 'NotSpecified',
    Owner = 'Owner',
    Administration = 'Administration',
    Broker = 'Broker',
    Other = 'Other',
}

export enum PaymentMethod
{
    IBAN = 'IBAN',
    ESR = 'ESR',
    QR = 'QR',
}

export class PaymentInformationDto
{
    public iban?: string;
    public referenceNumber?: string;
    public esrAccountNumber?: string;
    public name?: string;
    public paymentMethod: PaymentMethod;

    public constructor(init?: Partial<PaymentInformationDto>) { (Object as any).assign(this, init); }
}

export enum PartType
{
    NotSpecified = 'NotSpecified',
    Custom = 'Custom',
    Oven = 'Oven',
    Bathroom = 'Bathroom',
    BalconyTerrace = 'BalconyTerrace',
    Lighting = 'Lighting',
    Floor = 'Floor',
    Boiler = 'Boiler',
    Letterbox = 'Letterbox',
    RoofGutter = 'RoofGutter',
    SteamExtraction = 'SteamExtraction',
    Ceiling = 'Ceiling',
    Shower = 'Shower',
    Screed = 'Screed',
    Facade = 'Facade',
    RidgePlate = 'RidgePlate',
    MosquitoScreen = 'MosquitoScreen',
    Railing = 'Railing',
    Dishwasher = 'Dishwasher',
    HouseWall = 'HouseWall',
    Heating = 'Heating',
    Insulation = 'Insulation',
    Cables = 'Cables',
    Basement = 'Basement',
    CookingStove = 'CookingStove',
    Kitchen = 'Kitchen',
    Fridge = 'Fridge',
    Lavabo = 'Lavabo',
    Drainage = 'Drainage',
    SewerSystem = 'SewerSystem',
    Elevator = 'Elevator',
    Masonry = 'Masonry',
    Pergola = 'Pergola',
    ShopWindow = 'ShopWindow',
    Sockets = 'Sockets',
    Fusebox = 'Fusebox',
    Blindbox = 'Blindbox',
    Carpet = 'Carpet',
    Tumbler = 'Tumbler',
    Underroof = 'Underroof',
    Walls = 'Walls',
    HeatPump = 'HeatPump',
    LaundryRoom = 'LaundryRoom',
    WashingMachine = 'WashingMachine',
    Wc = 'Wc',
    Inverter = 'Inverter',
    WinterGarden = 'WinterGarden',
    SolarThermal = 'SolarThermal',
    SolarPhotovoltaic = 'SolarPhotovoltaic',
    SolarHybrid = 'SolarHybrid',
    SlatBlinds = 'SlatBlinds',
    SunBlinds = 'SunBlinds',
    Chimney = 'Chimney',
    Coverings = 'Coverings',
    TileEternitRoof = 'TileEternitRoof',
    LightPanelsDome = 'LightPanelsDome',
    WoodenShingleRoof = 'WoodenShingleRoof',
    FlatRoof = 'FlatRoof',
    Windows = 'Windows',
    WindowBrokenGlass = 'WindowBrokenGlass',
    WindowFrame = 'WindowFrame',
    WindowSeals = 'WindowSeals',
    WindowShutter = 'WindowShutter',
    Doors = 'Doors',
    DoorBrokenGlass = 'DoorBrokenGlass',
    DoorFrame = 'DoorFrame',
    DoorSeals = 'DoorSeals',
    Garage = 'Garage',
    Greenhouse = 'Greenhouse',
    Planting = 'Planting',
    GardenShed = 'GardenShed',
    CarShelter = 'CarShelter',
    GardenWall = 'GardenWall',
    BicycleShelter = 'BicycleShelter',
    Forecourt = 'Forecourt',
    Fence = 'Fence',
    Storeroom = 'Storeroom',
    JacuzziPool = 'JacuzziPool',
    WaspNest = 'WaspNest',
    PrivacyWall = 'PrivacyWall',
}

export enum DamageLocation
{
    NotSpecified = 'NotSpecified',
    Building = 'Building',
    Surrounding = 'Surrounding',
}

export class PartDto
{
    public damageId?: string;
    public partType: PartType;
    public damageLocation: DamageLocation;
    public name?: string;
    public estimatedValue: number;
    public estimatedAge: number;
    public isAgeUnknown: boolean;
    public isAmountMoreThan6?: boolean;
    public amount: number;
    public description?: string;
    public isConnectedToTheBuilding?: boolean;

    public constructor(init?: Partial<PartDto>) { (Object as any).assign(this, init); }
}

export class SnoozeDto
{
    public reason?: string;
    public until: string;
    public by?: IdentityDto;
    public disabled: boolean;

    public constructor(init?: Partial<SnoozeDto>) { (Object as any).assign(this, init); }
}

export enum NotificationType
{
    NotSpecified = 'NotSpecified',
    ChatOnly = 'ChatOnly',
    Activation = 'Activation',
    RequestFeedback = 'RequestFeedback',
    ConfirmClaimCorrection = 'ConfirmClaimCorrection',
    RequestPaymentInformation = 'RequestPaymentInformation',
    CoverageScope = 'CoverageScope',
    CraftsmanCompletion = 'CraftsmanCompletion',
    RegressSummary = 'RegressSummary',
    RequestDocuments = 'RequestDocuments',
    GrantPaymentCap = 'GrantPaymentCap',
    AnnouncePayment = 'AnnouncePayment',
    PaymentOverview = 'PaymentOverview',
    BenefitOverview = 'BenefitOverview',
    Refusal = 'Refusal',
    FollowUpOffer = 'FollowUpOffer',
    FollowUpInvoice = 'FollowUpInvoice',
}

export class ClientInteractionDto
{
    public notificationType: NotificationType;
    public executedBy?: IdentityDto;
    public executedAt?: string;

    public constructor(init?: Partial<ClientInteractionDto>) { (Object as any).assign(this, init); }
}

export enum OpenIssueAction
{
    Activate = 'Activate',
    CategorizeDocuments = 'CategorizeDocuments',
    ReadMessage = 'ReadMessage',
    CaptureInvoice = 'CaptureInvoice',
    SecondApproval = 'SecondApproval',
    Close = 'Close',
    ReviewClaimReportLink = 'ReviewClaimReportLink',
    CheckQuote = 'CheckQuote',
    RequestDocuments = 'RequestDocuments',
    RequestDocumentsAgain = 'RequestDocumentsAgain',
    AssignCraftsman = 'AssignCraftsman',
    ConfirmClaimCorrection = 'ConfirmClaimCorrection',
    RequestQuote = 'RequestQuote',
    CraftsmanFinishMessage = 'CraftsmanFinishMessage',
    AssignNewEstimationExpert = 'AssignNewEstimationExpert',
    ChangeContract = 'ChangeContract',
    CheckRefusal = 'CheckRefusal',
}

export class OpenIssueActionDto
{
    public action: OpenIssueAction;
    public createdAt: string;
    public activeFrom: string;

    public constructor(init?: Partial<OpenIssueActionDto>) { (Object as any).assign(this, init); }
}

export enum CauseName
{
    NotSpecified = 'NotSpecified',
    Rechtsschutz = 'Rechtsschutz',
    NichtVersichertVorsaetzlicheBeschaedigung = 'NichtVersichertVorsaetzlicheBeschaedigung',
    NichtVersichertAndereUrsachen = 'NichtVersichertAndereUrsachen',
    NichtVersichertTechnischeStoerung = 'NichtVersichertTechnischeStoerung',
    NichtVersichertDeckungUeberAnderesProdukt = 'NichtVersichertDeckungUeberAnderesProdukt',
    NichtVersichertDritthaftung = 'NichtVersichertDritthaftung',
    NichtVersichertBaumangel = 'NichtVersichertBaumangel',
    NichtVersichertWasserschaden = 'NichtVersichertWasserschaden',
    NichtVersichertUnterhalt = 'NichtVersichertUnterhalt',
    NichtVersichertGrobfahrlaessigkeit = 'NichtVersichertGrobfahrlaessigkeit',
    NichtVersichertFrost = 'NichtVersichertFrost',
    CascoAllRiskWind = 'CascoAllRiskWind',
    CascoAllRiskAndereUrsachen = 'CascoAllRiskAndereUrsachen',
    CascoAllRiskAnprallZusammensturzAufprall = 'CascoAllRiskAnprallZusammensturzAufprall',
    FeuerSengschadenSchmorschaden = 'FeuerSengschadenSchmorschaden',
    WasserschadenSolaranlagenKorrision = 'WasserschadenSolaranlagenKorrision',
    WasserschadenHeizkreislaufKorrision = 'WasserschadenHeizkreislaufKorrision',
    WasserschadenUebrigeKorrision = 'WasserschadenUebrigeKorrision',
    WasserschadenSprinklerKorrision = 'WasserschadenSprinklerKorrision',
    WasserschadenAbwasserKorrision = 'WasserschadenAbwasserKorrision',
    WasserschadenNutzwasserKorrision = 'WasserschadenNutzwasserKorrision',
    WasserschadenUnbekannteUrsache = 'WasserschadenUnbekannteUrsache',
    WasserschadenRueckstauGrundwasserRueckstau = 'WasserschadenRueckstauGrundwasserRueckstau',
    WasserschadenRueckstauGrundwasserInfiltration = 'WasserschadenRueckstauGrundwasserInfiltration',
    WasserschadenRegenSchneeSchmelzwasser = 'WasserschadenRegenSchneeSchmelzwasser',
    WasserschadenRegenSchneeSchmelzwasserKaminEntlueftungAndereUrsache = 'WasserschadenRegenSchneeSchmelzwasserKaminEntlueftungAndereUrsache',
    WasserschadenRegenSchneeSchmelzwasserKaminEntlueftungFehlverhaltenMensch = 'WasserschadenRegenSchneeSchmelzwasserKaminEntlueftungFehlverhaltenMensch',
    WasserschadenRegenSchneeSchmelzwasserKaminEntlueftungTechnischerDefektAlter = 'WasserschadenRegenSchneeSchmelzwasserKaminEntlueftungTechnischerDefektAlter',
    WasserschadenRegenSchneeSchmelzwasserFensterTuerenAndereUrsache = 'WasserschadenRegenSchneeSchmelzwasserFensterTuerenAndereUrsache',
    WasserschadenRegenSchneeSchmelzwasserFensterTuerenFehlverhaltenMensch = 'WasserschadenRegenSchneeSchmelzwasserFensterTuerenFehlverhaltenMensch',
    WasserschadenRegenSchneeSchmelzwasserFensterTuerenTechnischerDefektAlter = 'WasserschadenRegenSchneeSchmelzwasserFensterTuerenTechnischerDefektAlter',
    WasserschadenRegenSchneeSchmelzwasserNeigedachAndereUrsache = 'WasserschadenRegenSchneeSchmelzwasserNeigedachAndereUrsache',
    WasserschadenRegenSchneeSchmelzwasserNeigedachFehlverhaltenMensch = 'WasserschadenRegenSchneeSchmelzwasserNeigedachFehlverhaltenMensch',
    WasserschadenRegenSchneeSchmelzwasserNeigedachTechnischerDefektAlter = 'WasserschadenRegenSchneeSchmelzwasserNeigedachTechnischerDefektAlter',
    WasserschadenRegenSchneeSchmelzwasserFlachdachandereUrsache = 'WasserschadenRegenSchneeSchmelzwasserFlachdachandereUrsache',
    WasserschadenRegenSchneeSchmelzwasserFlachdachFehlverhaltenMensch = 'WasserschadenRegenSchneeSchmelzwasserFlachdachFehlverhaltenMensch',
    WasserschadenRegenSchneeSchmelzwasserFlachdachTechnischerDefektAlter = 'WasserschadenRegenSchneeSchmelzwasserFlachdachTechnischerDefektAlter',
    WasserschadenLeitungsbruchSolaranlagenAndereUrsache = 'WasserschadenLeitungsbruchSolaranlagenAndereUrsache',
    WasserschadenLeitungsbruchSolaranlagenFehlmanipulationenArbeiten = 'WasserschadenLeitungsbruchSolaranlagenFehlmanipulationenArbeiten',
    WasserschadenLeitungsbruchSolaranlagenFrost = 'WasserschadenLeitungsbruchSolaranlagenFrost',
    WasserschadenLeitungsbruchHeizkreislaufAndereUrsache = 'WasserschadenLeitungsbruchHeizkreislaufAndereUrsache',
    WasserschadenLeitungsbruchHeizkreislaufFehlmanipulationenArbeiten = 'WasserschadenLeitungsbruchHeizkreislaufFehlmanipulationenArbeiten',
    WasserschadenLeitungsbruchHeizkreislaufFrost = 'WasserschadenLeitungsbruchHeizkreislaufFrost',
    WasserschadenLeitungsbruchUebrigeAndereUrsache = 'WasserschadenLeitungsbruchUebrigeAndereUrsache',
    WasserschadenLeitungsbruchUebrigeFehlmanipulationenArbeiten = 'WasserschadenLeitungsbruchUebrigeFehlmanipulationenArbeiten',
    WasserschadenLeitungsbruchUebrigeFrost = 'WasserschadenLeitungsbruchUebrigeFrost',
    WasserschadenLeitungsbruchSprinklerAndereUrsache = 'WasserschadenLeitungsbruchSprinklerAndereUrsache',
    WasserschadenLeitungsbruchSprinklerFehlmanipulationenArbeiten = 'WasserschadenLeitungsbruchSprinklerFehlmanipulationenArbeiten',
    WasserschadenLeitungsbruchSprinklerFrost = 'WasserschadenLeitungsbruchSprinklerFrost',
    WasserschadenLeitungsbruchAbwasserAndereUrsache = 'WasserschadenLeitungsbruchAbwasserAndereUrsache',
    WasserschadenLeitungsbruchAbwasserFehlmanipulationenArbeiten = 'WasserschadenLeitungsbruchAbwasserFehlmanipulationenArbeiten',
    WasserschadenLeitungsbruchAbwasserFrost = 'WasserschadenLeitungsbruchAbwasserFrost',
    WasserschadenLeitungsbruchNutzwasseraAndereUrsache = 'WasserschadenLeitungsbruchNutzwasseraAndereUrsache',
    WasserschadenLeitungsbruchNutzwasserFehlmanipulationenArbeiten = 'WasserschadenLeitungsbruchNutzwasserFehlmanipulationenArbeiten',
    WasserschadenLeitungsbruchNutzwasserFrost = 'WasserschadenLeitungsbruchNutzwasserFrost',
    WasserschadenAusUeberlaufFluessigkeitenUebrige = 'WasserschadenAusUeberlaufFluessigkeitenUebrige',
    WasserschadenAusUeberlaufFluessigkeitenWaschmaschineGeschirrspuelerAndereUrsache = 'WasserschadenAusUeberlaufFluessigkeitenWaschmaschineGeschirrspuelerAndereUrsache',
    WasserschadenAusUeberlaufFluessigkeitenWaschmaschineGeschirrspuelerFehlmanipulationenArbeiten = 'WasserschadenAusUeberlaufFluessigkeitenWaschmaschineGeschirrspuelerFehlmanipulationenArbeiten',
    WasserschadenAusUeberlaufFluessigkeitenWaschmaschineGeschirrspuelerTechnischerDefekt = 'WasserschadenAusUeberlaufFluessigkeitenWaschmaschineGeschirrspuelerTechnischerDefekt',
    WasserschadenAusUeberlaufFluessigkeitenSanitaereEinrichtungenInklBadAndereUrsache = 'WasserschadenAusUeberlaufFluessigkeitenSanitaereEinrichtungenInklBadAndereUrsache',
    WasserschadenAusUeberlaufFluessigkeitenSanitaereEinrichtungenInklBadFehlmanipulationenArbeiten = 'WasserschadenAusUeberlaufFluessigkeitenSanitaereEinrichtungenInklBadFehlmanipulationenArbeiten',
    WasserschadenAusUeberlaufFluessigkeitenSanitaereEinrichtungenInklBadTechnischerDefekt = 'WasserschadenAusUeberlaufFluessigkeitenSanitaereEinrichtungenInklBadTechnischerDefekt',
    WasserschadenAusUeberlaufFluessigkeitenKondenswasserKuehlungAndereUrsache = 'WasserschadenAusUeberlaufFluessigkeitenKondenswasserKuehlungAndereUrsache',
    WasserschadenAusUeberlaufFluessigkeitenKondenswasserKuehlungFehlmanipulationenArbeiten = 'WasserschadenAusUeberlaufFluessigkeitenKondenswasserKuehlungFehlmanipulationenArbeiten',
    WasserschadenAusUeberlaufFluessigkeitenKondenswasserKuehlungTechnischerDefekt = 'WasserschadenAusUeberlaufFluessigkeitenKondenswasserKuehlungTechnischerDefekt',
    WasserschadenAusUeberlaufFluessigkeitenHeizoelAndereUrsache = 'WasserschadenAusUeberlaufFluessigkeitenHeizoelAndereUrsache',
    WasserschadenAusUeberlaufFluessigkeitenHeizoelFehlmanipulationenArbeiten = 'WasserschadenAusUeberlaufFluessigkeitenHeizoelFehlmanipulationenArbeiten',
    WasserschadenAusUeberlaufFluessigkeitenHeizoelTechnischerDefekt = 'WasserschadenAusUeberlaufFluessigkeitenHeizoelTechnischerDefekt',
    WasserschadenAusUeberlaufFluessigkeitenGefaesseAndereUrsache = 'WasserschadenAusUeberlaufFluessigkeitenGefaesseAndereUrsache',
    WasserschadenAusUeberlaufFluessigkeitenGefaesseFehlmanipulationenArbeiten = 'WasserschadenAusUeberlaufFluessigkeitenGefaesseFehlmanipulationenArbeiten',
    WasserschadenAusUeberlaufFluessigkeitenGefaesseTechnischerDefekt = 'WasserschadenAusUeberlaufFluessigkeitenGefaesseTechnischerDefekt',
    WasserschadenAusUeberlaufFluessigkeitenFluessigkeitkuehlungAndereUrsache = 'WasserschadenAusUeberlaufFluessigkeitenFluessigkeitkuehlungAndereUrsache',
    WasserschadenAusUeberlaufFluessigkeitenFluessigkeitkuehlungFehlmanipulationenArbeiten = 'WasserschadenAusUeberlaufFluessigkeitenFluessigkeitkuehlungFehlmanipulationenArbeiten',
    WasserschadenAusUeberlaufFluessigkeitenFluessigkeitkuehlungTechnischerDefekt = 'WasserschadenAusUeberlaufFluessigkeitenFluessigkeitkuehlungTechnischerDefekt',
    TerrorUnruheSachbeschaedigung = 'TerrorUnruheSachbeschaedigung',
    TerrorUnruheFeuerExplosionen = 'TerrorUnruheFeuerExplosionen',
    TerraErdbeben = 'TerraErdbeben',
    StiftungAndereTiere = 'StiftungAndereTiere',
    StiftungHangwasser = 'StiftungHangwasser',
    StiftungHangrutsch = 'StiftungHangrutsch',
    StiftungFrostdruck = 'StiftungFrostdruck',
    StiftungBergfluss = 'StiftungBergfluss',
    StiftungBergdruck = 'StiftungBergdruck',
    StiftungBaugrund = 'StiftungBaugrund',
    StiftungSchutzmassnahmen = 'StiftungSchutzmassnahmen',
    StiftungHausschwamm = 'StiftungHausschwamm',
    StiftungFeuerschaeden = 'StiftungFeuerschaeden',
    StiftungSturm = 'StiftungSturm',
    StiftungSchneerutsch = 'StiftungSchneerutsch',
    StiftungOberflaechenwasserUeberschwemmung = 'StiftungOberflaechenwasserUeberschwemmung',
    StiftungLawine = 'StiftungLawine',
    StiftungHagel = 'StiftungHagel',
    StiftungSteinschlag = 'StiftungSteinschlag',
    SolaranlageWind = 'SolaranlageWind',
    SolaranlageVorsaetzlicheBeschaedigung = 'SolaranlageVorsaetzlicheBeschaedigung',
    SolaranlageAnprallZusammensturzAufprall = 'SolaranlageAnprallZusammensturzAufprall',
    SolaranlageAndereUrsachenInnereEinwirkung = 'SolaranlageAndereUrsachenInnereEinwirkung',
    SolaranlageUeberlastUeberdrehzahlUnterdruck = 'SolaranlageUeberlastUeberdrehzahlUnterdruck',
    SolaranlageSchmierung = 'SolaranlageSchmierung',
    SolaranlageBedienung = 'SolaranlageBedienung',
    SolaranlageWassermangelWasserschlaegen = 'SolaranlageWassermangelWasserschlaegen',
    SolaranlageVersagenMessRegenOderSicherheitseinrichtungen = 'SolaranlageVersagenMessRegenOderSicherheitseinrichtungen',
    SolaranlageKurzschlussUeberspannungEinwirkungelektrStrom = 'SolaranlageKurzschlussUeberspannungEinwirkungelektrStrom',
    SolaranlageKonstruktionMaterialFabrikationsfehler = 'SolaranlageKonstruktionMaterialFabrikationsfehler',
    SolaranlageFremdkoerper = 'SolaranlageFremdkoerper',
    SolaranlageAndereUrsachen = 'SolaranlageAndereUrsachen',
    Gebaeudehaftpflichtversicherung = 'Gebaeudehaftpflichtversicherung',
    Bauherrenhaftpflichtversicherung = 'Bauherrenhaftpflichtversicherung',
    GebaeudetechnikWindAeussereEinwirkung = 'GebaeudetechnikWindAeussereEinwirkung',
    GebaeudetechnikVorsaetzlicheBeschaedigungAeussereEinwirkung = 'GebaeudetechnikVorsaetzlicheBeschaedigungAeussereEinwirkung',
    GebaeudetechnikAnprallZusammensturzAufprallAeussereEinwirkung = 'GebaeudetechnikAnprallZusammensturzAufprallAeussereEinwirkung',
    GebaeudetechnikAndereUrsachenAeussereEinwirkung = 'GebaeudetechnikAndereUrsachenAeussereEinwirkung',
    GebaeudetechnikUeberlastUeberdrehzahlUnterdruckInnereEinwirkung = 'GebaeudetechnikUeberlastUeberdrehzahlUnterdruckInnereEinwirkung',
    GebaeudetechnikungeeigneteroderfehlenderSchmierungInnereEinwirkung = 'GebaeudetechnikungeeigneteroderfehlenderSchmierungInnereEinwirkung',
    GebaeudetechnikfalscheBedienungInnereEinwirkung = 'GebaeudetechnikfalscheBedienungInnereEinwirkung',
    GebaeudetechnikWassermangelWasserschlaegenInnereEinwirkung = 'GebaeudetechnikWassermangelWasserschlaegenInnereEinwirkung',
    GebaeudetechnikVersagenvonMessRegenOderSicherheitseinrichtungenInnereEinwirkung = 'GebaeudetechnikVersagenvonMessRegenOderSicherheitseinrichtungenInnereEinwirkung',
    GebaeudetechnikKurzschlussUeberspannungEinwirkungElektrStromInnereEinwirkung = 'GebaeudetechnikKurzschlussUeberspannungEinwirkungElektrStromInnereEinwirkung',
    GebaeudetechnikKonstruktionMaterialFabrikationsfehlerInnereEinwirkung = 'GebaeudetechnikKonstruktionMaterialFabrikationsfehlerInnereEinwirkung',
    GebaeudetechnikFremdkoerperInnereEinwirkung = 'GebaeudetechnikFremdkoerperInnereEinwirkung',
    GebaeudetechnikAndereUrsachenInnereEinwirkung = 'GebaeudetechnikAndereUrsachenInnereEinwirkung',
    CascoGebaeudetechnikWind = 'CascoGebaeudetechnikWind',
    CascoGebaeudetechnikVorsaetzlicheBeschaedigung = 'CascoGebaeudetechnikVorsaetzlicheBeschaedigung',
    CascoGebaeudetechnikAnprallZusammensturzAufprall = 'CascoGebaeudetechnikAnprallZusammensturzAufprall',
    CascoGebaeudetechnikAndereUrsachenAussereEinwirkung = 'CascoGebaeudetechnikAndereUrsachenAussereEinwirkung',
    CascoGebaeudetechnikUeberlastUeberdrehzahlUnterdruck = 'CascoGebaeudetechnikUeberlastUeberdrehzahlUnterdruck',
    CascoGebaeudetechnikSchmierung = 'CascoGebaeudetechnikSchmierung',
    CascoGebaeudetechnikFalscheBedienung = 'CascoGebaeudetechnikFalscheBedienung',
    CascoGebaeudetechnikWassermangelWasserschlaegen = 'CascoGebaeudetechnikWassermangelWasserschlaegen',
    CascoGebaeudetechnikVersagenvonMessRegenOderSicherheitseinrichtungen = 'CascoGebaeudetechnikVersagenvonMessRegenOderSicherheitseinrichtungen',
    CascoGebaeudetechnikKurzschlussUeberspannungEinwirkungElektrStrom = 'CascoGebaeudetechnikKurzschlussUeberspannungEinwirkungElektrStrom',
    CascoGebaeudetechnikKonstruktionMaterialFabrikationsfehler = 'CascoGebaeudetechnikKonstruktionMaterialFabrikationsfehler',
    CascoGebaeudetechnikFremdkoerper = 'CascoGebaeudetechnikFremdkoerper',
    CascoGebaeudetechnikAndereUrsachen = 'CascoGebaeudetechnikAndereUrsachen',
    CascoGlasbruchbeweglicheVerglasung = 'CascoGlasbruchbeweglicheVerglasung',
    CascoGlasbruchTiffanyKunstGeritzteGlasflaechen = 'CascoGlasbruchTiffanyKunstGeritzteGlasflaechen',
    CascoGlasbruchLavabosSpueltrogCloset = 'CascoGlasbruchLavabosSpueltrogCloset',
    CascoGlasbruchGlaskeramik = 'CascoGlasbruchGlaskeramik',
    CascoGlasbruchAbdeckungen = 'CascoGlasbruchAbdeckungen',
    CascoGlasbruchUebrige = 'CascoGlasbruchUebrige',
    CascoGlasbruchWerbeflaechen = 'CascoGlasbruchWerbeflaechen',
    CascoGlasbruchSchaufensterSpezialglaeser = 'CascoGlasbruchSchaufensterSpezialglaeser',
    CascoGlasbruchSchaufensterNormalglas = 'CascoGlasbruchSchaufensterNormalglas',
    CascoGlasbruchLichtkuppel = 'CascoGlasbruchLichtkuppel',
    CascoGlasbruchKirchenfenster = 'CascoGlasbruchKirchenfenster',
    CascoGlasbruchGlaeserSonnenkollektoren = 'CascoGlasbruchGlaeserSonnenkollektoren',
    CascoGlasbruchFensterTuerenSpezialglaeser = 'CascoGlasbruchFensterTuerenSpezialglaeser',
    CascoGlasbruchFensterTuerenNormalglas = 'CascoGlasbruchFensterTuerenNormalglas',
    CascoGebaeudeeinsturz = 'CascoGebaeudeeinsturz',
    CascoFahrzeuganprallUmgebung = 'CascoFahrzeuganprallUmgebung',
    CascoFahrzeuganprallGebaeude = 'CascoFahrzeuganprallGebaeude',
    CascoEinbruchDiebstahlUebrige = 'CascoEinbruchDiebstahlUebrige',
    CascoEinbruchDiebstahlInstandstellungGeraeteHauswart = 'CascoEinbruchDiebstahlInstandstellungGeraeteHauswart',
    Bauwesen = 'Bauwesen',
    FeuerschadenUnbekannteUrsache = 'FeuerschadenUnbekannteUrsache',
    FeuerschadenMotorfahrzeugeallesinklFahrzeugElektrikElektronik = 'FeuerschadenMotorfahrzeugeallesinklFahrzeugElektrikElektronik',
    FeuerschadenMechanischeEnergieReibungMahlenHaeckselnHackenHeuStrohHolz = 'FeuerschadenMechanischeEnergieReibungMahlenHaeckselnHackenHeuStrohHolz',
    FeuerschadenMechanischeEnergieReibungMaschinenMotoren = 'FeuerschadenMechanischeEnergieReibungMaschinenMotoren',
    FeuerschadenUnbekanntSprengstoffanschlag = 'FeuerschadenUnbekanntSprengstoffanschlag',
    FeuerschadenUnbekanntBrandstiftung = 'FeuerschadenUnbekanntBrandstiftung',
    FeuerschadenDrittpersonSprengstoffanschlag = 'FeuerschadenDrittpersonSprengstoffanschlag',
    FeuerschadenDrittpersonBrandstiftung = 'FeuerschadenDrittpersonBrandstiftung',
    FeuerschadenEigentuemerSprengstoffanschlag = 'FeuerschadenEigentuemerSprengstoffanschlag',
    FeuerschadenEigentuemerBrandstiftung = 'FeuerschadenEigentuemerBrandstiftung',
    FeuerschadenIndirekterBlitzschlag = 'FeuerschadenIndirekterBlitzschlag',
    FeuerschadenDirekterBlitzschlagOhneBlitzschutzanlage = 'FeuerschadenDirekterBlitzschlagOhneBlitzschutzanlage',
    FeuerschadenDirekterBlitzschlagMitBlitzschutzanlage = 'FeuerschadenDirekterBlitzschlagMitBlitzschutzanlage',
    FeuerschadenFeuerschadenUnsachgemaesseVerwendungApparatenWaermestrahlungWaermestau = 'FeuerschadenFeuerschadenUnsachgemaesseVerwendungApparatenWaermestrahlungWaermestau',
    FeuerschadenUnsachgemaesseVerwendungApparatenUnachtsamkeitKochHaushaltgeraete = 'FeuerschadenUnsachgemaesseVerwendungApparatenUnachtsamkeitKochHaushaltgeraete',
    FeuerschadenMangelanGeraetenWerkzeugeMaschinenMotoren = 'FeuerschadenMangelanGeraetenWerkzeugeMaschinenMotoren',
    FeuerschadenMangelanGeraetenUnterhaltungselektronikPc = 'FeuerschadenMangelanGeraetenUnterhaltungselektronikPc',
    FeuerschadenMangelUebrigeHaushaltapparate = 'FeuerschadenMangelUebrigeHaushaltapparate',
    FeuerschadenMangelKochgeraete = 'FeuerschadenMangelKochgeraete',
    FeuerschadenMangelInstallationenKurzschluss = 'FeuerschadenMangelInstallationenKurzschluss',
    FeuerschadenMangelInstallationenErdschluss = 'FeuerschadenMangelInstallationenErdschluss',
    FeuerschadenMangelInstallationenUeberlast = 'FeuerschadenMangelInstallationenUeberlast',
    FeuerschadenExplosionApparateBehaelterHandhabungManipulation = 'FeuerschadenExplosionApparateBehaelterHandhabungManipulation',
    FeuerschadenExplosionApparateBehaelterbaultechnMangel = 'FeuerschadenExplosionApparateBehaelterbaultechnMangel',
    FeuerschadenStaubexplosion = 'FeuerschadenStaubexplosion',
    FeuerschadenMeteore = 'FeuerschadenMeteore',
    FeuerschadenSprengstoffexplosionenUnsachgemaesseLagerung = 'FeuerschadenSprengstoffexplosionenUnsachgemaesseLagerung',
    FeuerschadenSprengstoffexplosionenHandhabungManipulation = 'FeuerschadenSprengstoffexplosionenHandhabungManipulation',
    FeuerschadenSprengstoffexplosionenTechnischerMangel = 'FeuerschadenSprengstoffexplosionenTechnischerMangel',
    FeuerschadenGasexplosionenBrandHandhabungManipulation = 'FeuerschadenGasexplosionenBrandHandhabungManipulation',
    FeuerschadenGasexplosionenBrandFluessiggasCampinggas = 'FeuerschadenGasexplosionenBrandFluessiggasCampinggas',
    FeuerschadenGasexplosionenBrandAnderesGas = 'FeuerschadenGasexplosionenBrandAnderesGas',
    FeuerschadenGasexplosionenBrandTechnMangel = 'FeuerschadenGasexplosionenBrandTechnMangel',
    FeuerschadenGasexplosionenBrandDichteLeitungenErdgas = 'FeuerschadenGasexplosionenBrandDichteLeitungenErdgas',
    FeuerschadenGasexplosionenBrandBauarbeiten = 'FeuerschadenGasexplosionenBrandBauarbeiten',
    FeuerschadenVerunreinigteFaserstoffe = 'FeuerschadenVerunreinigteFaserstoffe',
    FeuerschadenChemikalienUnsachgemaesseLagerung = 'FeuerschadenChemikalienUnsachgemaesseLagerung',
    FeuerschadenChemikalienHandhabungManipulation = 'FeuerschadenChemikalienHandhabungManipulation',
    FeuerschadenHeuGaerungnichtMahlenHaeckseln = 'FeuerschadenHeuGaerungnichtMahlenHaeckseln',
    FeuerschadenKochgasHandhabungManipulation = 'FeuerschadenKochgasHandhabungManipulation',
    FeuerschadenKochgasbaultechnMangel = 'FeuerschadenKochgasbaultechnMangel',
    FeuerschadenFeuerwerkWunderkerzenBengalhoelzer = 'FeuerschadenFeuerwerkWunderkerzenBengalhoelzer',
    FeuerschadenFeuerwerkHandhabungManipulation = 'FeuerschadenFeuerwerkHandhabungManipulation',
    FeuerschadenFeuerwerktechnischerMangel = 'FeuerschadenFeuerwerktechnischerMangel',
    FeuerschadenFeuerwerkUnsachgemaesseLagerung = 'FeuerschadenFeuerwerkUnsachgemaesseLagerung',
    FeuerschadenSchweissenLoetenBauarbeitenArbeitsprozessAuftauenLeitungen = 'FeuerschadenSchweissenLoetenBauarbeitenArbeitsprozessAuftauenLeitungen',
    FeuerschadenSchweissenLoetenBauarbeitenArbeitsprozessSchweissarbeiten = 'FeuerschadenSchweissenLoetenBauarbeitenArbeitsprozessSchweissarbeiten',
    FeuerschadenSchweissenLoetenBauarbeitenArbeitsprozessTrennschleifen = 'FeuerschadenSchweissenLoetenBauarbeitenArbeitsprozessTrennschleifen',
    FeuerschadenSchweissenLoetenBauarbeitenArbeitsprozessFolienschweissen = 'FeuerschadenSchweissenLoetenBauarbeitenArbeitsprozessFolienschweissen',
    FeuerschadenSchweissenLoetenBauarbeitenArbeitsprozessErhitzenBitumen = 'FeuerschadenSchweissenLoetenBauarbeitenArbeitsprozessErhitzenBitumen',
    FeuerschadenKerzenunkorrekterAufstellungsort = 'FeuerschadenKerzenunkorrekterAufstellungsort',
    FeuerschadenKerzenFehlerhaftesProdukt = 'FeuerschadenKerzenFehlerhaftesProdukt',
    FeuerschadenKerzenUnachtsamkeit = 'FeuerschadenKerzenUnachtsamkeit',
    FeuerschadenRaucherwarenUnachtsamkeit = 'FeuerschadenRaucherwarenUnachtsamkeit',
    FeuerschadenRaucherwarenUnsachgemaesseEntsorgung = 'FeuerschadenRaucherwarenUnsachgemaesseEntsorgung',
    FeuerschadenZuendhoelzerFeuerzeugeUnachtsamkeit = 'FeuerschadenZuendhoelzerFeuerzeugeUnachtsamkeit',
    FeuerschadenZuendhoelzerFeuerzeugespielendeKinder = 'FeuerschadenZuendhoelzerFeuerzeugespielendeKinder',
    FeuerschadenFeuerungsrueckstaendeUnsachgemaesseEntsorgung = 'FeuerschadenFeuerungsrueckstaendeUnsachgemaesseEntsorgung',
    FeuerschadenRauchabzugsanlagenGlanzrussKaminbrand = 'FeuerschadenRauchabzugsanlagenGlanzrussKaminbrand',
    FeuerschadenRauchabzugsanlagenUngepruefteAnlage = 'FeuerschadenRauchabzugsanlagenUngepruefteAnlage',
    FeuerschadenRauchabzugsanlagenTechnischerMangel = 'FeuerschadenRauchabzugsanlagenTechnischerMangel',
    FeuerschadenRauchabzugsanlagenBaulicherMangel = 'FeuerschadenRauchabzugsanlagenBaulicherMangel',
    FeuerschadenIndustriefeuerungGrossanlInklSpaeneSchnitzelLagerung = 'FeuerschadenIndustriefeuerungGrossanlInklSpaeneSchnitzelLagerung',
    FeuerschadenIndustriefeuerungGrossanlinklSpaeneSchnitzelHandhabungManipulation = 'FeuerschadenIndustriefeuerungGrossanlinklSpaeneSchnitzelHandhabungManipulation',
    FeuerschadenIndustriefeuerungGrossanlInklSpaeneSchnitzelBaultechnMangel = 'FeuerschadenIndustriefeuerungGrossanlInklSpaeneSchnitzelBaultechnMangel',
    FeuerschadenZentralheizungenInklHolzfeuerungenHandhabungManipulation = 'FeuerschadenZentralheizungenInklHolzfeuerungenHandhabungManipulation',
    FeuerschadenZentralheizungenInklHolzfeuerungenbaultechnMangel = 'FeuerschadenZentralheizungenInklHolzfeuerungenbaultechnMangel',
    FeuerschadenZimmeroefenInklChemineoefenbaulMangelAufstellung = 'FeuerschadenZimmeroefenInklChemineoefenbaulMangelAufstellung',
    FeuerschadenZimmeroefenInklChemineoefenHandhabungManipulation = 'FeuerschadenZimmeroefenInklChemineoefenHandhabungManipulation',
    FeuerschadenZimmeroefenInklChemineoefenTechnischerMangel = 'FeuerschadenZimmeroefenInklChemineoefenTechnischerMangel',
    FeuerschadenCheminesInklKacheloefenBaultechnMangel = 'FeuerschadenCheminesInklKacheloefenBaultechnMangel',
    FeuerschadenCheminesInklKacheloefenHandhabungManipulation = 'FeuerschadenCheminesInklKacheloefenHandhabungManipulation',
    ElementarVoegel = 'ElementarVoegel',
    ElementarWildtiere = 'ElementarWildtiere',
    ElementarAndereTiere = 'ElementarAndereTiere',
    ElementarNager = 'ElementarNager',
    ElementarMarder = 'ElementarMarder',
    ElementarInsekten = 'ElementarInsekten',
    ElementarHolzschaedlinge = 'ElementarHolzschaedlinge',
    ElementarVandalismusUmgebungAndere = 'ElementarVandalismusUmgebungAndere',
    ElementarVandalismusUmgebungGraffiti = 'ElementarVandalismusUmgebungGraffiti',
    ElementarVandalismusGebaeudeAndere = 'ElementarVandalismusGebaeudeAndere',
    ElementarVandalismusGebaeudeGraffiti = 'ElementarVandalismusGebaeudeGraffiti',
    ElementarPraeventiveSofortmassnahmenUeberschwemmung = 'ElementarPraeventiveSofortmassnahmenUeberschwemmung',
    ElementarPraeventiveSofortmassnahmenSteinschlag = 'ElementarPraeventiveSofortmassnahmenSteinschlag',
    ElementarPraeventiveSofortmassnahmenSchneefall = 'ElementarPraeventiveSofortmassnahmenSchneefall',
    ElementarPraeventiveSofortmassnahmenBaeume = 'ElementarPraeventiveSofortmassnahmenBaeume',
    ElementarErdfall = 'ElementarErdfall',
    ElementarSturmwind = 'ElementarSturmwind',
    ElementarSchneedruckSchneerutsch = 'ElementarSchneedruckSchneerutsch',
    ElementarLawinen = 'ElementarLawinen',
    ElementarHochwasserUeberschwemmung = 'ElementarHochwasserUeberschwemmung',
    ElementarHagel = 'ElementarHagel',
    ElementarSteinschlag = 'ElementarSteinschlag',
    ElementarErdrutsch = 'ElementarErdrutsch',
    ElementarFelssturz = 'ElementarFelssturz',
    ElementarDiebstahlGebauedeteileUmgebung = 'ElementarDiebstahlGebauedeteileUmgebung',
    ElementarDiebstahlGebauedeteileGebaeude = 'ElementarDiebstahlGebauedeteileGebaeude',
    TerraVulkanischeEruptionen = 'TerraVulkanischeEruptionen',
    WasserschadenNutzwasserUnterhalt = 'WasserschadenNutzwasserUnterhalt',
    WasserschadenAbwasserUnterhalt = 'WasserschadenAbwasserUnterhalt',
    WasserschadenSprinklerUnterhalt = 'WasserschadenSprinklerUnterhalt',
    WasserschadenUebrigeUnterhalt = 'WasserschadenUebrigeUnterhalt',
    WasserschadenHeizkreislaufUnterhalt = 'WasserschadenHeizkreislaufUnterhalt',
    WasserschadenSolaranlagenUnterhalt = 'WasserschadenSolaranlagenUnterhalt',
}

export enum CauseType
{
    NotSpecified = 'NotSpecified',
    Covered = 'Covered',
    NotCovered = 'NotCovered',
    Foundation = 'Foundation',
}

export enum ReferentCode
{
    NotSpecified = 'NotSpecified',
    E = 'E',
    L = 'L',
    NV = 'NV',
    C_AR = 'C_AR',
    W = 'W',
    F = 'F',
    R = 'R',
    BST = 'BST',
    SPG = 'SPG',
    S = 'S',
    V = 'V',
    G = 'G',
    C = 'C',
    TU = 'TU',
    B = 'B',
}

export class CauseDto
{
    public id?: string;
    public name: CauseName;
    public type: CauseType;
    public referentCode: ReferentCode;
    public referent?: string;
    public code?: string;
    public causeGroup?: string;
    public cause?: string;
    public detail?: string;
    public detailCode?: string;
    public detail2?: string;
    public detail2Code?: string;
    public descriptionCode?: string;

    public constructor(init?: Partial<CauseDto>) { (Object as any).assign(this, init); }
}

export enum ProcessingType
{
    Unspecified = 'Unspecified',
    Assistance = 'Assistance',
    PayFast = 'PayFast',
    PayFastSmart = 'PayFastSmart',
    PayByDocument = 'PayByDocument',
    SmartMedium = 'SmartMedium',
    PayByDocumentAnalog = 'PayByDocumentAnalog',
    RequestOffer = 'RequestOffer',
    Expert = 'Expert',
    ExpertEstimate = 'ExpertEstimate',
    ExpertInspectionReport = 'ExpertInspectionReport',
    Refusal = 'Refusal',
    LegalProtection = 'LegalProtection',
    ConstructionOrLiability = 'ConstructionOrLiability',
}

export class ClaimReportLinkReviewDto
{
    public hasReviewedDamageReporter: boolean;
    public hasReviewedAttachments: boolean;
    public hasReviewedParts: boolean;
    public hasReviewedIban: boolean;
    public hasReviewedProcessingType: boolean;
    public isReviewed: boolean;

    public constructor(init?: Partial<ClaimReportLinkReviewDto>) { (Object as any).assign(this, init); }
}

export class ClaimReportLinkDto
{
    public claimReportId?: string;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public comment?: string;
    public causeOfLoss?: string;
    public emailBody?: string;
    public damageOccuredAt?: string;
    public phone?: string;
    public mobile?: string;
    public email?: string;
    public role: CustomerType;
    public ibanOwnerName?: string;
    public ibanOwnerLastName?: string;
    public iban?: string;
    public messageChannel?: ClaimMessageChannel;
    public language?: string;
    public parts?: PartDto[];
    public numberOfFiles: number;
    public repairWorkState: RepairWorkState;
    public review?: ClaimReportLinkReviewDto;
    public claimReportCreatedAt: string;
    public createdAt: string;
    public createdBy?: IdentityDto;

    public constructor(init?: Partial<ClaimReportLinkDto>) { (Object as any).assign(this, init); }
}

export enum RegressActivityResult
{
    NoPayments = 'NoPayments',
    PercentagePayments = 'PercentagePayments',
    RegularPayments = 'RegularPayments',
    NoRequestNecessary = 'NoRequestNecessary',
    Requested = 'Requested',
    DocumentsReceived = 'DocumentsReceived',
    NoRegress = 'NoRegress',
    Regress = 'Regress',
}

export enum RegressAction
{
    Start = 'Start',
    DocumentImpactOnPayments = 'DocumentImpactOnPayments',
    RequestExternalDocuments = 'RequestExternalDocuments',
    Finish = 'Finish',
}

export class RegressAuditOpenIssueActionDto
{
    public action: RegressAction;
    public createdAt: string;
    public activeFrom: string;

    public constructor(init?: Partial<RegressAuditOpenIssueActionDto>) { (Object as any).assign(this, init); }
}

export class AddressDto
{
    public street?: string;
    public buildingNr?: string;
    public deliveryNote?: string;
    public place?: string;
    public placeCode?: string;
    public postalCode?: string;
    public district?: string;
    public xCoordinate?: number;
    public yCoordinate?: number;
    public latitude?: number;
    public longitude?: number;

    public constructor(init?: Partial<AddressDto>) { (Object as any).assign(this, init); }
}

export class AreaAssignmentDto
{
    public areaId?: string;
    public estimationExpertCircle?: number;
    public placeCode?: string;
    public place?: string;
    public district?: string;

    public constructor(init?: Partial<AreaAssignmentDto>) { (Object as any).assign(this, init); }
}

export enum BuildingValueType
{
    NotSpecified = 'NotSpecified',
    FesteSummeMitErstrisiko = 'FesteSummeMitErstrisiko',
    ErstrisikoMitVereinbarterSumme = 'ErstrisikoMitVereinbarterSumme',
    FesteSumme = 'FesteSumme',
    Abbruchwert = 'Abbruchwert',
    Neuwert = 'Neuwert',
    ReduzierterNeuwert = 'ReduzierterNeuwert',
    VereinbarteSumme = 'VereinbarteSumme',
    WertartUnbekannt = 'WertartUnbekannt',
    Zeitwert = 'Zeitwert',
}

export enum Salutation
{
    NotSpecified = 'NotSpecified',
    None = 'None',
    Mr = 'Mr',
    Mrs = 'Mrs',
    MrAndMrs = 'MrAndMrs',
}

export enum PartnerRole
{
    Owner = 'Owner',
    Grosskunde = 'Grosskunde',
    Privatkunde = 'Privatkunde',
    Regresszahler = 'Regresszahler',
    Schadenbeaftragter = 'Schadenbeaftragter',
    Schaetzungsexperte = 'Schaetzungsexperte',
    Vertragspartner = 'Vertragspartner',
    Zahlungsempfaenger = 'Zahlungsempfaenger',
}

export class PartnerDto
{
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public nameAddition?: string;
    public partnerNumber: number;
    public role: PartnerRole;
    public physicalAddress?: AddressDto;

    public constructor(init?: Partial<PartnerDto>) { (Object as any).assign(this, init); }
}

export enum DeductibleType
{
    NotSpecified = 'NotSpecified',
    PercentageDamage = 'PercentageDamage',
    PercentageInsurance = 'PercentageInsurance',
    FixedAmount = 'FixedAmount',
}

export class DeductibleDto
{
    public startDate: string;
    public name?: string;
    public code: number;
    public description?: string;
    public cancellationSign: boolean;
    public deviation: boolean;
    public minValue: number;
    public maxValue: number;
    public value: number;
    public unit?: string;
    public unitDescription: DeductibleType;

    public constructor(init?: Partial<DeductibleDto>) { (Object as any).assign(this, init); }
}

export enum CoverageRestriction
{
    NotSpecified = 'NotSpecified',
    SpecialTerms = 'SpecialTerms',
    RiskExclusion = 'RiskExclusion',
    SpecialTermsAndRiskExclusion = 'SpecialTermsAndRiskExclusion',
}

export class CoverageDto
{
    public startDate: string;
    public id: number;
    public code?: string;
    public name?: string;
    public insuranceSum: number;
    public deductibles?: DeductibleDto[];
    public coverStartDate: string;
    public exceededClaimsCount: boolean;
    public amountClaims?: number;
    public hasClaimsWithExceededSum: boolean;
    public restriction?: CoverageRestriction;

    public constructor(init?: Partial<CoverageDto>) { (Object as any).assign(this, init); }
}

export class ContractNoteDto
{
    public text?: string;
    public severity?: string;
    public code?: string;

    public constructor(init?: Partial<ContractNoteDto>) { (Object as any).assign(this, init); }
}

export enum StrainLevel
{
    NotSpecified = 'NotSpecified',
    Clear = 'Clear',
    Warning = 'Warning',
    Exceeded = 'Exceeded',
}

export class ContractDto
{
    public coveragesShort?: string[];
    public contractNumber?: string;
    public startDate: string;
    public endDate: string;
    public version: number;
    public generalTermsOfContract?: string;
    public generalTermsOfContractLegalProtection?: string;
    public buildingAddress?: AddressDto;
    public areaAssignment?: AreaAssignmentDto;
    public buildingValueType: BuildingValueType;
    public contractualPartner?: PartnerDto;
    public coverages?: CoverageDto[];
    public notes?: ContractNoteDto[];
    public estimateExpNo?: string;
    public estimateExpName?: string;
    public estimateExpCircle?: number;
    public technAdvisNo?: string;
    public technAdvisName?: string;
    public isRemediated: boolean;
    public remediationDate?: string;
    public uninsuredStartDate?: string;
    public uninsuredEndDate?: string;
    public strainLevel?: StrainLevel;
    public damageRatio?: number;
    public contractAdvisorFirstname?: string;
    public contractAdvisorLastname?: string;

    public constructor(init?: Partial<ContractDto>) { (Object as any).assign(this, init); }
}

export enum ClaimStatus
{
    NotSet = 'NotSet',
    New = 'New',
    InProgress = 'InProgress',
    Closed = 'Closed',
}

export class ClosureDto
{
    public closedAt: string;
    public closedBy?: IdentityDto;

    public constructor(init?: Partial<ClosureDto>) { (Object as any).assign(this, init); }
}

export class ContractChangeRequestDto
{
    public createdAt: string;
    public createdBy?: IdentityDto;
    public note?: string;

    public constructor(init?: Partial<ContractChangeRequestDto>) { (Object as any).assign(this, init); }
}

export class PrejudiceCheckDto
{
    public passed: boolean;
    public reason?: string;
    public createdBy?: IdentityDto;
    public createdAt: string;

    public constructor(init?: Partial<PrejudiceCheckDto>) { (Object as any).assign(this, init); }
}

export class CraftsmanRatingSummaryDto
{
    public communication?: number;
    public reliability?: number;
    public repairRating?: number;

    public constructor(init?: Partial<CraftsmanRatingSummaryDto>) { (Object as any).assign(this, init); }
}

export class CraftsmanDto
{
    public id?: string;
    public company?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public phoneNumber?: string;
    public street?: string;
    public postalCode: number;
    public place?: string;
    public industries?: string[];
    public isDeleted: boolean;
    public latitude?: number;
    public longitude?: number;
    public distance?: number;
    public rating?: CraftsmanRatingSummaryDto;
    public iban?: string;
    public usageCount: number;
    public hasAccount: boolean;
    public comment?: string;
    public language?: string;
    public isInoperative: boolean;
    public createdAt?: string;
    public lastUpdatedAt?: string;
    public lastUpdatedBy?: IdentityDto;

    public constructor(init?: Partial<CraftsmanDto>) { (Object as any).assign(this, init); }
}

export class CraftsmanRatingDto
{
    public reliability?: number;
    public communication?: number;
    public repair?: number;
    public comment?: string;
    public echonovumLink?: string;

    public constructor(init?: Partial<CraftsmanRatingDto>) { (Object as any).assign(this, init); }
}

export class CraftsmanAssignmentDto
{
    public craftsman?: CraftsmanDto;
    public craftsmanRating?: CraftsmanRatingDto;

    public constructor(init?: Partial<CraftsmanAssignmentDto>) { (Object as any).assign(this, init); }
}

export enum CraftsmanJobState
{
    NotSpecified = 'NotSpecified',
    Requested = 'Requested',
    Accepted = 'Accepted',
    AppointmentEntered = 'AppointmentEntered',
    QuoteEntered = 'QuoteEntered',
    JobApproved = 'JobApproved',
    Fixing = 'Fixing',
    Fixed = 'Fixed',
    Closed = 'Closed',
    Declined = 'Declined',
}

export class CraftsmanJobDto
{
    public craftsman?: CraftsmanDto;
    public state: CraftsmanJobState;
    public craftsmanJobEmailError?: CraftsmanJobEmailErrorDto;
    public requestedBy?: IdentityDto;
    public requestedAt?: string;
    public acceptedAt?: string;
    public appointment?: string;
    public appointmentEnteredAt?: string;
    public quoteEnteredAt?: string;
    public approvedAt?: string;
    public fixingAt?: string;
    public fixingAtEnteredAt?: string;
    public fixedAt?: string;
    public closedAt?: string;
    public declinedAt?: string;
    public noInspection?: boolean;
    public price?: number;
    public description?: string;
    public quoteRemarks?: string;
    public invoiceByLetterPost?: boolean;

    public constructor(init?: Partial<CraftsmanJobDto>) { (Object as any).assign(this, init); }
}

export enum FollowUp
{
    AcceptJob = 'AcceptJob',
    InspectionDate = 'InspectionDate',
    QuoteRequest = 'QuoteRequest',
    FixingDate = 'FixingDate',
    ConfirmFixing = 'ConfirmFixing',
    InvoicePending = 'InvoicePending',
    CustomerInvoicePending = 'CustomerInvoicePending',
    CustomerOfferPending = 'CustomerOfferPending',
}

export class FollowUpItemDto
{
    public followUp: FollowUp;
    public since: string;

    public constructor(init?: Partial<FollowUpItemDto>) { (Object as any).assign(this, init); }
}

export class ClaimListDto
{
    public id?: string;
    public claimSapId?: string;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public messageChannel: ClaimMessageChannel;
    public email?: string;
    public phone?: string;
    public mobile?: string;
    public language?: string;
    public repairWorkState: RepairWorkState;
    public customerType: CustomerType;
    public otherRole?: string;
    public partnerNr?: string;
    public paymentInformation?: PaymentInformationDto;
    public parts?: PartDto[];
    public snooze?: SnoozeDto;
    public clientInteractions?: ClientInteractionDto[];
    public openIssueActions?: OpenIssueActionDto[];
    public oldestOpenIssueAction?: OpenIssueActionDto;
    public cause?: CauseDto;
    public processingType: ProcessingType;
    public triagedAt: string;
    public triageDecisionComment?: string;
    public claimReportLinks?: ClaimReportLinkDto[];
    public hasPendingNachtragCheck: boolean;
    public hasNachtragPaymentsWithFirstApproval: boolean;
    public hasRegressAudit: boolean;
    public regressAuditStartedAt?: string;
    public regressActivityResult?: RegressActivityResult;
    public regressAuditOpenIssueActions?: RegressAuditOpenIssueActionDto[];
    public damageOccuredAt: string;
    public contractNumber?: string;
    public contract?: ContractDto;
    public latitude?: number;
    public longitude?: number;
    public status: ClaimStatus;
    public backOfficeAssignee?: IdentityDto;
    public backOfficeClosure?: ClosureDto;
    public inClarificationByBackOfficeAssignee: boolean;
    public estimationExpertCircle?: number;
    public estimationExpertAssignmentComment?: string;
    public estimationExpertAssignee?: IdentityDto;
    public estimationExpertClosure?: ClosureDto;
    public contractChangeRequestByEstimationExpert?: ContractChangeRequestDto;
    public lastPrejudiceCheck?: PrejudiceCheckDto;
    public craftsmanAssignments?: CraftsmanAssignmentDto[];
    public craftsmanAssignedAt?: string;
    public caseHandlerRead: boolean;
    public craftsmanJob?: CraftsmanJobDto;
    public followUp?: FollowUpItemDto;
    public totalCoveredAmount?: number;
    public createdAt: string;
    public lastUpdatedAt: string;
    public createdBy?: IdentityDto;
    public lastUpdatedBy?: IdentityDto;

    public constructor(init?: Partial<ClaimListDto>) { (Object as any).assign(this, init); }
}

export enum MessageType
{
    NotSpecified = 'NotSpecified',
    Event = 'Event',
}

export enum ClaimAttachmentType
{
    Uncategorized = 'Uncategorized',
    Invoice = 'Invoice',
    Offer = 'Offer',
    DamagePhoto = 'DamagePhoto',
    Account = 'Account',
    Complaint = 'Complaint',
    ClaimNotes = 'ClaimNotes',
    Other = 'Other',
    CostOverview = 'CostOverview',
    PaymentOverview = 'PaymentOverview',
    BenefitOverview = 'BenefitOverview',
    BaseDocument = 'BaseDocument',
    Visa = 'Visa',
    Certificate = 'Certificate',
}

export class ClaimAttachmentAssignmentDto
{
    public type: IdentityType;
    public display?: string;
    public id?: string;

    public constructor(init?: Partial<ClaimAttachmentAssignmentDto>) { (Object as any).assign(this, init); }
}

export class ClaimAttachmentDto
{
    public id?: string;
    public name?: string;
    public title?: string;
    public type: ClaimAttachmentType;
    public uploadedBy?: IdentityDto;
    public createdAt: string;
    public lastUpdatedAt: string;
    public size: number;
    public relevant: boolean;
    public quoteChecked?: boolean;
    public sapCode?: string;
    public assignment?: ClaimAttachmentAssignmentDto;

    public constructor(init?: Partial<ClaimAttachmentDto>) { (Object as any).assign(this, init); }
}

export class DeliveryDto
{
    public correlationId?: string;
    public status: DeliveryStatus;
    public description?: string;
    public code?: number;
    public enhancedCode?: string;
    public lastUpdated: string;

    public constructor(init?: Partial<DeliveryDto>) { (Object as any).assign(this, init); }
}

export class QrHeaderDto
{
    public qrType?: string;
    public version?: string;
    public codingType: number;

    public constructor(init?: Partial<QrHeaderDto>) { (Object as any).assign(this, init); }
}

export class QrAddressDto
{
    public addressType: string;
    public name?: string;
    public streetOrAddressLine1?: string;
    public buildingNumberOrAddressLine2?: string;
    public postalCode: number;
    public town?: string;
    public country?: string;

    public constructor(init?: Partial<QrAddressDto>) { (Object as any).assign(this, init); }
}

export class QrPaymentReferenceDto
{
    public referenceType?: string;
    public reference?: string;

    public constructor(init?: Partial<QrPaymentReferenceDto>) { (Object as any).assign(this, init); }
}

export class QrAdditionalInformationDto
{
    public unstructuredMessage?: string;
    public trailer?: string;

    public constructor(init?: Partial<QrAdditionalInformationDto>) { (Object as any).assign(this, init); }
}

export class QrBillDto
{
    public id?: string;
    public header?: QrHeaderDto;
    public iban?: string;
    public creditor?: QrAddressDto;
    public ultimateCreditor?: QrAddressDto;
    public amount: number;
    public currency?: string;
    public ultimateDebtor?: QrAddressDto;
    public paymentReference?: QrPaymentReferenceDto;
    public additionalInformation?: QrAdditionalInformationDto;
    public qrCodeString?: string;

    public constructor(init?: Partial<QrBillDto>) { (Object as any).assign(this, init); }
}

export class MessageDto
{
    public id?: string;
    public correlationId?: string;
    public claimId?: string;
    public sender?: IdentityDto;
    public text?: string;
    public createdAt: string;
    public type?: MessageType;
    public attachments?: ClaimAttachmentDto[];
    public isReadByGvb: boolean;
    public delivery?: DeliveryDto;
    public isSmsOrEmailResponse: boolean;
    public attachedOverview?: string;
    public channel: ClaimMessageChannel;
    public paymentId?: string;
    public notificationType: NotificationType;
    public qrBill?: QrBillDto;

    public constructor(init?: Partial<MessageDto>) { (Object as any).assign(this, init); }
}

export class ClaimWithLastMessageDto
{
    public claim?: ClaimListDto;
    public lastMessage?: MessageDto;

    public constructor(init?: Partial<ClaimWithLastMessageDto>) { (Object as any).assign(this, init); }
}

export class ClaimMetadataDto
{
    public claimId?: string;
    public lastUpdatedAt: string;
    public closedByEstimationExpert: boolean;
    public cancelled: boolean;

    public constructor(init?: Partial<ClaimMetadataDto>) { (Object as any).assign(this, init); }
}

export class MailgunWebhookSignature
{
    public signature?: string;
    public token?: string;
    public timestamp: number;

    public constructor(init?: Partial<MailgunWebhookSignature>) { (Object as any).assign(this, init); }
}

export class MailgunWebhookDeliveryStatus
{
    public message?: string;
    public code?: number;
    // @DataMember(Name="enhanced-code")
    public enhanced_code?: string;

    public constructor(init?: Partial<MailgunWebhookDeliveryStatus>) { (Object as any).assign(this, init); }
}

export class MailgunWebhookMessageHeader
{
    // @DataMember(Name="Message-Id")
    public Message_Id?: string;

    public subject?: string;
    public from?: string;
    public to?: string;

    public constructor(init?: Partial<MailgunWebhookMessageHeader>) { (Object as any).assign(this, init); }
}

export class MailgunWebhookMessage
{
    public headers?: MailgunWebhookMessageHeader;

    public constructor(init?: Partial<MailgunWebhookMessage>) { (Object as any).assign(this, init); }
}

export class MailgunWebhookEventData
{
    public event?: string;
    public severity?: string;
    public tags?: string[];
    public reason?: string;
    // @DataMember(Name="user-variables")
    public user_variables?: { [index: string]: string; };

    // @DataMember(Name="delivery-status")
    public delivery_status?: MailgunWebhookDeliveryStatus;

    public message?: MailgunWebhookMessage;

    public constructor(init?: Partial<MailgunWebhookEventData>) { (Object as any).assign(this, init); }
}

export interface IMailgunWebhookRequest
{
    signature?: MailgunWebhookSignature;
}

export enum ClaimReportOrigin
{
    NotSpecified = 'NotSpecified',
    WebForm = 'WebForm',
    PhoneCall = 'PhoneCall',
    Email = 'Email',
}

export class OrderedRequest
{
    /** @description Comma- or semicolon separated list of fields to sort by. To change sort order add a '-' in front of the field */
    // @ApiMember(DataType="string", Description="Comma- or semicolon separated list of fields to sort by. To change sort order add a '-' in front of the field", Name="OrderBy", ParameterType="query", Verb="GET")
    public orderBy?: string;

    public constructor(init?: Partial<OrderedRequest>) { (Object as any).assign(this, init); }
}

export interface IClaimAware
{
    claimId?: string;
}

export class PagedRequest
{
    /** @description The page number (1-based). Default: 1 */
    // @ApiMember(DataType="int", Description="The page number (1-based). Default: 1", Name="Page", ParameterType="query", Verb="GET")
    public page: number;

    /** @description The page size */
    // @ApiMember(DataType="int", Description="The page size", Name="PageSize", ParameterType="query", Verb="GET")
    public pageSize: number;

    public constructor(init?: Partial<PagedRequest>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode?: string;

    // @DataMember(Order=2)
    public fieldName?: string;

    // @DataMember(Order=3)
    public message?: string;

    // @DataMember(Order=4)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode?: string;

    // @DataMember(Order=2)
    public message?: string;

    // @DataMember(Order=3)
    public stackTrace?: string;

    // @DataMember(Order=4)
    public errors?: ResponseError[];

    // @DataMember(Order=5)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export enum ClaimReportState
{
    Draft = 'Draft',
    Submitted = 'Submitted',
    Editing = 'Editing',
    Triaged = 'Triaged',
    Error = 'Error',
}

export class PagedAndOrderedRequest extends PagedRequest
{
    /** @description Comma- or semicolon separated list of fields to sort by. To change sort order add a '-' in front of the field */
    // @ApiMember(DataType="string", Description="Comma- or semicolon separated list of fields to sort by. To change sort order add a '-' in front of the field", Name="OrderBy", ParameterType="query", Verb="GET")
    public orderBy?: string;

    public constructor(init?: Partial<PagedAndOrderedRequest>) { super(init); (Object as any).assign(this, init); }
}

export class PerpetratorDto
{
    public name?: string;
    public liabilityInsurance?: string;

    public constructor(init?: Partial<PerpetratorDto>) { (Object as any).assign(this, init); }
}

export interface IUpdateTriage
{
    id?: string;
    origin: ClaimReportOrigin;
    claimSapId?: string;
    amountCovered?: number;
    processingType: ProcessingType;
    cause?: CauseDto;
    perpetrator?: PerpetratorDto;
    contract?: ContractDto;
    damageOccuredAt: string;
    comment?: string;
    craftsmanId?: string;
    ignoreSapWarning: boolean;
    ignoreAssignment: boolean;
    messageChannel?: ClaimMessageChannel;
    email?: string;
    mobile?: string;
    estimationExpertUsername?: string;
    isAlreadyRecordedInSap: boolean;
    duplicateId?: string;
    suppressDuplicateReview: boolean;
}

export enum FilterSet
{
    OpenIssue = 'OpenIssue',
    MediumPool = 'MediumPool',
    SecondApprovals = 'SecondApprovals',
    FollowUp = 'FollowUp',
    OpenRegressAudits = 'OpenRegressAudits',
    RegressAudits = 'RegressAudits',
}

export enum FollowUpFilter
{
    All = 'All',
    Craftsman = 'Craftsman',
    InvoiceFollowUps = 'InvoiceFollowUps',
    OfferFollowUps = 'OfferFollowUps',
}

export enum ClaimSuggestedBy
{
    Unspecified = 'Unspecified',
    Other = 'Other',
    Customer = 'Customer',
    CaseHandler = 'CaseHandler',
    EstimationExpert = 'EstimationExpert',
}

export enum MediumProcessingType
{
    Unspecified = 'Unspecified',
    PayFlat = 'PayFlat',
    PayInvoice = 'PayInvoice',
}

export enum EstimationExpertOpenIssueActionType
{
    NotSpecified = 'NotSpecified',
    CloseClaim = 'CloseClaim',
    FindInspectionDate = 'FindInspectionDate',
    WaitingForInspectionDate = 'WaitingForInspectionDate',
    InspectionToday = 'InspectionToday',
    CreateCostOverview = 'CreateCostOverview',
    ClaimInvoicing = 'ClaimInvoicing',
    CheckDuplicates = 'CheckDuplicates',
    OpenPrejudiceCheck = 'OpenPrejudiceCheck',
}

export class EstimationExpertOpenIssueActionDto
{
    public since: string;
    public type: EstimationExpertOpenIssueActionType;

    public constructor(init?: Partial<EstimationExpertOpenIssueActionDto>) { (Object as any).assign(this, init); }
}

export class InspectionDto
{
    public id: string;
    public date?: string;

    public constructor(init?: Partial<InspectionDto>) { (Object as any).assign(this, init); }
}

export enum EstimationExpertSnoozeType
{
    NotSpecified = 'NotSpecified',
    WaitForInvoice = 'WaitForInvoice',
    WaitForQuote = 'WaitForQuote',
    Others = 'Others',
}

export class RegressActivityDto
{
    public action: RegressAction;
    public result?: RegressActivityResult;
    public percentPayable?: number;
    public dueUntil?: string;
    public comment?: string;
    public createdAt: string;
    public createdBy?: IdentityDto;

    public constructor(init?: Partial<RegressActivityDto>) { (Object as any).assign(this, init); }
}

export enum ContractRemediationDecision
{
    NotSpecified = 'NotSpecified',
    Warn = 'Warn',
    Terminate = 'Terminate',
    RemediationOffer = 'RemediationOffer',
    NoAction = 'NoAction',
}

export enum BenefitType
{
    NotSpecified = 'NotSpecified',
    Feuer = 'Feuer',
    Elementar = 'Elementar',
    Top = 'Top',
    Plus = 'Plus',
    Aqua = 'Aqua',
    AquaFolgekosten = 'AquaFolgekosten',
    Casco = 'Casco',
    CascoGlasbruch = 'CascoGlasbruch',
    CascoEinbruchDiebstahl = 'CascoEinbruchDiebstahl',
    CascoAnprall = 'CascoAnprall',
    CascoGebaeudeeinsturz = 'CascoGebaeudeeinsturz',
    CascoMieterertrag = 'CascoMieterertrag',
    Terra = 'Terra',
    TerraFolgekosten = 'TerraFolgekosten',
    Solar = 'Solar',
    SolarFolgekosten = 'SolarFolgekosten',
    SolarErtragsausfallMehrkosten = 'SolarErtragsausfallMehrkosten',
    Tech = 'Tech',
    GM = 'GM',
    Rechtsschutz = 'Rechtsschutz',
    RechtsschutzLight = 'RechtsschutzLight',
    Bauwesen = 'Bauwesen',
    BauherrenHaftpflicht = 'BauherrenHaftpflicht',
    GebaeudeHaftfplicht = 'GebaeudeHaftfplicht',
    TopFlexa = 'TopFlexa',
    FeuerElementarBaulicheEinrichtungen = 'FeuerElementarBaulicheEinrichtungen',
    BauherrenHaftpflichtFremdeBauten = 'BauherrenHaftpflichtFremdeBauten',
    BauherrenHaftpflichtVertraglicheHaftung = 'BauherrenHaftpflichtVertraglicheHaftung',
    BauherrenHaftpflichtVermoegenschaeden = 'BauherrenHaftpflichtVermoegenschaeden',
    BauwesenBetriebsunterbrechungAufrechterhaltung = 'BauwesenBetriebsunterbrechungAufrechterhaltung',
    BauwesenAufraeumungsDekontaminationskosten = 'BauwesenAufraeumungsDekontaminationskosten',
    BauwesenArteserversicherung = 'BauwesenArteserversicherung',
    BauwesenBetriebsunterbrechungVerspaeteteInbetriebnahme = 'BauwesenBetriebsunterbrechungVerspaeteteInbetriebnahme',
    BauwesenKombi = 'BauwesenKombi',
    CascoAllRisk = 'CascoAllRisk',
    CascoExtendedCoverage = 'CascoExtendedCoverage',
    CascoExtendedCoverage2 = 'CascoExtendedCoverage2',
    CascoExtendedCoverage3 = 'CascoExtendedCoverage3',
    CascoGlasbruch2 = 'CascoGlasbruch2',
    CascoHausGebaeudetechnik = 'CascoHausGebaeudetechnik',
    ElementarAusserkantonal = 'ElementarAusserkantonal',
    FeuerAusserkantonal = 'FeuerAusserkantonal',
    AusserkantonalSpezial = 'AusserkantonalSpezial',
    AusserkantonalFolgekosten = 'AusserkantonalFolgekosten',
    GebaeudeHaftfplichtAssistance = 'GebaeudeHaftfplichtAssistance',
    TerraMietertrag = 'TerraMietertrag',
    TopBaulicheEinrichtungen = 'TopBaulicheEinrichtungen',
    Umgebungskosten = 'Umgebungskosten',
    ZusaetzlicheUmgebungskosten = 'ZusaetzlicheUmgebungskosten',
    Vorsorgeversicherung = 'Vorsorgeversicherung',
    AquaFreilegungskosten = 'AquaFreilegungskosten',
}

export class DamageByCoverageDto
{
    public amount: number;
    public benefitType: BenefitType;
    public benefitTypeCode?: string;

    public constructor(init?: Partial<DamageByCoverageDto>) { (Object as any).assign(this, init); }
}

export class CreateAttachment
{
    public mimeType?: string;
    public name?: string;
    public formDataReference?: string;
    public type: ClaimAttachmentType;

    public constructor(init?: Partial<CreateAttachment>) { (Object as any).assign(this, init); }
}

export enum MyGvbLanguage
{
    German = 'German',
    French = 'French',
}

export enum SmartClaimsApp
{
    NotSpecified = 'NotSpecified',
    Smarty = 'Smarty',
    Isa = 'Isa',
}

export enum Interval
{
    None = 'None',
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Yearly = 'Yearly',
}

export enum BadRequestErrorCode
{
    NotSpecified = 'NotSpecified',
    StrainLevelExceeded = 'StrainLevelExceeded',
    FileNotAllowedToBeDeleted = 'FileNotAllowedToBeDeleted',
}

export enum DamageType
{
    NotSpecified = 'NotSpecified',
    Hagel = 'Hagel',
    Sturmwind = 'Sturmwind',
    BlitzschlagDirekt = 'BlitzschlagDirekt',
    BlitzschlagIndirekt = 'BlitzschlagIndirekt',
    WasserAusUeberlauf = 'WasserAusUeberlauf',
    WasserFrost = 'WasserFrost',
    WasserLeitungsbruch = 'WasserLeitungsbruch',
    WasserRueckstau = 'WasserRueckstau',
    WasserGrundHangwasser = 'WasserGrundHangwasser',
    WasserHochwasserUeberschwemmung = 'WasserHochwasserUeberschwemmung',
    WasserRegenSchneeSchmelzwasser = 'WasserRegenSchneeSchmelzwasser',
    WasserUnbekannt = 'WasserUnbekannt',
    Glasbruch = 'Glasbruch',
    Tiere = 'Tiere',
    Vandalismus = 'Vandalismus',
    Feuer = 'Feuer',
    Fahrzeuganprall = 'Fahrzeuganprall',
    Einbruch = 'Einbruch',
    Gebaeudeeinsturz = 'Gebaeudeeinsturz',
    Erdrutsch = 'Erdrutsch',
    Lawine = 'Lawine',
    Steinschlag = 'Steinschlag',
    Erdbeben = 'Erdbeben',
    Schneedruck = 'Schneedruck',
    Gebaeudetechnik = 'Gebaeudetechnik',
    Solaranlage = 'Solaranlage',
    Bauwesen = 'Bauwesen',
    Gebaeudehaftpflicht = 'Gebaeudehaftpflicht',
    Bauherrenhaftpflicht = 'Bauherrenhaftpflicht',
    Rechtsschutz = 'Rechtsschutz',
    Andere = 'Andere',
}

export enum WaterOrigin
{
    NotSpecified = 'NotSpecified',
    Outside = 'Outside',
    Inside = 'Inside',
    Unknown = 'Unknown',
}

export enum Pest
{
    NotSpecified = 'NotSpecified',
    Rodents = 'Rodents',
    Insects = 'Insects',
    Woodpest = 'Woodpest',
    Martens = 'Martens',
    Birds = 'Birds',
    Others = 'Others',
}

export enum VandalismType
{
    NotSpecified = 'NotSpecified',
    Graffiti = 'Graffiti',
    Others = 'Others',
}

export class BuildingDto
{
    public address?: string;
    public street?: string;
    public buildingNo?: string;
    public postalCode?: number;
    public place?: string;
    public contractNumber?: string;
    public company?: string;
    public latitude: number;
    public longitude: number;

    public constructor(init?: Partial<BuildingDto>) { (Object as any).assign(this, init); }
}

export class ContractPartnerDto
{
    public firstName?: string;
    public lastName?: string;
    public company?: string;

    public constructor(init?: Partial<ContractPartnerDto>) { (Object as any).assign(this, init); }
}

export class ReporterDto
{
    public subjectId?: string;
    public firstName?: string;
    public lastName?: string;

    public constructor(init?: Partial<ReporterDto>) { (Object as any).assign(this, init); }
}

export class CraftsmanLookupDto
{
    public id?: string;
    public company?: string;
    public firstName?: string;
    public lastName?: string;
    public street?: string;
    public place?: string;
    public postalCode: number;
    public industries?: string[];

    public constructor(init?: Partial<CraftsmanLookupDto>) { (Object as any).assign(this, init); }
}

export class Dictionary<T> { [Key: string]: T; }

export class JsonObject extends Dictionary<string>
{

    public constructor(init?: Partial<JsonObject>) { super(); (Object as any).assign(this, init); }
}

// @DataContract
export class AzureEventGridEvent
{
    // @DataMember(Name="id")
    public id?: string;

    // @DataMember(Name="topic")
    public topic?: string;

    // @DataMember(Name="subject")
    public subject?: string;

    // @DataMember(Name="data")
    public data?: JsonObject;

    // @DataMember(Name="eventType")
    public eventType?: string;

    // @DataMember(Name="eventTime")
    public eventTime?: string;

    // @DataMember(Name="metadataVersion")
    public metadataVersion?: string;

    // @DataMember(Name="dataVersion")
    public dataVersion?: string;

    public constructor(init?: Partial<AzureEventGridEvent>) { (Object as any).assign(this, init); }
}

export enum CoverageType
{
    NotSpecified = 'NotSpecified',
    Feuer = 'Feuer',
    Elementar = 'Elementar',
    Top = 'Top',
    TopFlexa = 'TopFlexa',
    Plus = 'Plus',
    Aqua = 'Aqua',
    Casco = 'Casco',
    Terra = 'Terra',
    Solar = 'Solar',
    Tech = 'Tech',
    GM = 'GM',
    Rechtsschutz = 'Rechtsschutz',
    Bauwesen = 'Bauwesen',
    BauherrenHaftpflicht = 'BauherrenHaftpflicht',
    GebaeudeHaftfplicht = 'GebaeudeHaftfplicht',
}

export enum ActivityType
{
    NotSpecified = 'NotSpecified',
    Gebaeudeschaden = 'Gebaeudeschaden',
    Aufraeumungskosten = 'Aufraeumungskosten',
    Neuwert = 'Neuwert',
    KostenloseBauversicherung = 'KostenloseBauversicherung',
    HoehereAufraeumungskosten = 'HoehereAufraeumungskosten',
    Vandalismus = 'Vandalismus',
    MarderNagerInsektenWildtiere = 'MarderNagerInsektenWildtiere',
    PraeventiveSofortmassnahmen = 'PraeventiveSofortmassnahmen',
    DiebstahlGebaeudebestandteile = 'DiebstahlGebaeudebestandteile',
    SengUndSchmorschaeden = 'SengUndSchmorschaeden',
    UmgebungsschadenFeuerElementar = 'UmgebungsschadenFeuerElementar',
    UmgebungsschadenErdfall = 'UmgebungsschadenErdfall',
    UmgebungsschadenWildtier = 'UmgebungsschadenWildtier',
    FreilegungUndLecksuchkosten = 'FreilegungUndLecksuchkosten',
    GeraeteMaterialien = 'GeraeteMaterialien',
    Dekontamination = 'Dekontamination',
    Baufuehrungskosten = 'Baufuehrungskosten',
    GebaeudebeschaedigungenEinbruch = 'GebaeudebeschaedigungenEinbruch',
    Schlossaenderungskosten = 'Schlossaenderungskosten',
    MuenzKartenAutomatenWohngebaeude = 'MuenzKartenAutomatenWohngebaeude',
    ProvisorischeSofortmassnahmen = 'ProvisorischeSofortmassnahmen',
    UebrigeKosten = 'UebrigeKosten',
    Mietertragausfall = 'Mietertragausfall',
    EinsatzWasserortungsgeraetSuchkosten = 'EinsatzWasserortungsgeraetSuchkosten',
    GlasbruchGebaeudebeschaedigung = 'GlasbruchGebaeudebeschaedigung',
    GlasbruchProvisorischeSofortmassnahmen = 'GlasbruchProvisorischeSofortmassnahmen',
    EinbruchDiebstahlGebaeudebeschaedigung = 'EinbruchDiebstahlGebaeudebeschaedigung',
    EinbruchDiebstahlGeraeteMaterialien = 'EinbruchDiebstahlGeraeteMaterialien',
    EinbruchDiebstahlSchlossaenderungskosten = 'EinbruchDiebstahlSchlossaenderungskosten',
    EinbruchDiebstahlMuenzKartenautomaten = 'EinbruchDiebstahlMuenzKartenautomaten',
    EinbruchDiebstahlProvisorischeSofortmassnahmen = 'EinbruchDiebstahlProvisorischeSofortmassnahmen',
    FahrzeuganprallGebaeudebeschaedigung = 'FahrzeuganprallGebaeudebeschaedigung',
    FahrzeuganprallProvisorischeSofortmassnahmen = 'FahrzeuganprallProvisorischeSofortmassnahmen',
    Gebaeudeeinsturz = 'Gebaeudeeinsturz',
    GebaeudeeinsturzProvisorischeSofortmassnahmen = 'GebaeudeeinsturzProvisorischeSofortmassnahmen',
    FolgeUndKomplementaerschaeden = 'FolgeUndKomplementaerschaeden',
    Erdbewegungskosten = 'Erdbewegungskosten',
    ZusaetzlicheLebenshaltungskosten = 'ZusaetzlicheLebenshaltungskosten',
    ExpertenkostenNotreparatur = 'ExpertenkostenNotreparatur',
    UebrigeSachUndFolgekosten = 'UebrigeSachUndFolgekosten',
    BaulicheAnlageUmgebung = 'BaulicheAnlageUmgebung',
    MehrkostenBetriebsaufrechterhaltung = 'MehrkostenBetriebsaufrechterhaltung',
    Gebaeudetechnik = 'Gebaeudetechnik',
    Folgekosten = 'Folgekosten',
    Ertragsausfall = 'Ertragsausfall',
    MehrkostenProvisorien = 'MehrkostenProvisorien',
    Solar = 'Solar',
    AllgemeineFolgekosten = 'AllgemeineFolgekosten',
    FolgeschaedenGebaeudeFahrhabe = 'FolgeschaedenGebaeudeFahrhabe',
    ErtragsausfallMehrkosten = 'ErtragsausfallMehrkosten',
    Zinsen = 'Zinsen',
    ElementarZinsen = 'ElementarZinsen',
    FeuerZinsen = 'FeuerZinsen',
    FolgekostenZinsen = 'FolgekostenZinsen',
    GlasbruchZinsen = 'GlasbruchZinsen',
    EinbruchDiebstahlZinsen = 'EinbruchDiebstahlZinsen',
    FahrzeuganprallZinsen = 'FahrzeuganprallZinsen',
    GebaeudeeinsturzZinsen = 'GebaeudeeinsturzZinsen',
    MietertragZinsen = 'MietertragZinsen',
    ErstattungSelbstbehalt = 'ErstattungSelbstbehalt',
    Selbstbehalt = 'Selbstbehalt',
    GlasbruchSelbstbehalt = 'GlasbruchSelbstbehalt',
    EinbruchDiebstahlSelbstbehalt = 'EinbruchDiebstahlSelbstbehalt',
    AnprallSelbstbehalt = 'AnprallSelbstbehalt',
    GebaeudeeinsturzSelbstbehalt = 'GebaeudeeinsturzSelbstbehalt',
    MietertragSelbstbehalt = 'MietertragSelbstbehalt',
    Vorsteuerabzug = 'Vorsteuerabzug',
    Kulanz = 'Kulanz',
    KulanzGlasbruch = 'KulanzGlasbruch',
    KulanzEinbruchdiebstahl = 'KulanzEinbruchdiebstahl',
    KulanzAnprall = 'KulanzAnprall',
    KulanzGebaeudeeinsturz = 'KulanzGebaeudeeinsturz',
    KulanzMietertragausfall = 'KulanzMietertragausfall',
    KulanzFolgekosten = 'KulanzFolgekosten',
    KulanzMehrkostenErtragsausfall = 'KulanzMehrkostenErtragsausfall',
    KulanzLight = 'KulanzLight',
}

export class BenefitDto
{
    public coverageType: CoverageType;
    public activityType: ActivityType;
    public benefitType: BenefitType;
    public benefitTypeCode?: string;

    public constructor(init?: Partial<BenefitDto>) { (Object as any).assign(this, init); }
}

export class UserDataDto
{
    public userId?: string;
    public username?: string;
    public displayName?: string;
    public hidePicture: boolean;
    public isEstimationExpert: boolean;

    public constructor(init?: Partial<UserDataDto>) { (Object as any).assign(this, init); }
}

export class UsersOnClaimDto
{
    public claimId?: string;
    public users?: UserDataDto[];

    public constructor(init?: Partial<UsersOnClaimDto>) { (Object as any).assign(this, init); }
}

export class ClaimAttachmentUsageDto
{
    public claimAttachmentId?: string;
    public usageId?: string;
    public description?: string;
    public isMessageReference: boolean;
    public isD41Reference: boolean;

    public constructor(init?: Partial<ClaimAttachmentUsageDto>) { (Object as any).assign(this, init); }
}

export class DuplicateDto
{
    public isAlreadyRecordedInSap?: boolean;
    public duplicateId?: string;

    public constructor(init?: Partial<DuplicateDto>) { (Object as any).assign(this, init); }
}

export class TriageDecisionDto
{
    public amountCovered?: number;
    public by?: IdentityDto;
    public createdAt: string;
    public cause?: CauseDto;
    public processingType?: ProcessingType;
    public meldestatus?: string;
    public comment?: string;
    public estimationExpertUsername?: string;
    public duplicate?: DuplicateDto;

    public constructor(init?: Partial<TriageDecisionDto>) { (Object as any).assign(this, init); }
}

export class ClaimReportListItemDto
{
    public documentId?: string;
    public sapId?: string;
    public claimId?: string;
    public displayName?: string;
    public company?: string;
    public address?: string;
    public damageType?: string;
    public createdAt: string;
    public claimReportOrigin: ClaimReportOrigin;
    public parts?: PartDto[];
    public damageOccuredAt?: string;
    public assistanceFavored: boolean;
    public latitude?: number;
    public longitude?: number;
    public state: ClaimReportState;
    public username?: string;
    public triageDecision?: TriageDecisionDto;

    public constructor(init?: Partial<ClaimReportListItemDto>) { (Object as any).assign(this, init); }
}

export class CorrelationDto
{
    public claimId?: string;
    public claimSapId?: string;
    public claimReportId?: string;
    public processingType?: ProcessingType;
    public cause?: CauseDto;
    public claimStatus?: ClaimStatus;
    public claimReportStatus: ClaimReportState;
    public damageOccuredAt: string;
    public street?: string;
    public buildingNr?: string;
    public place?: string;
    public placeCode?: string;
    public entityType?: string;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public createdAt: string;

    public constructor(init?: Partial<CorrelationDto>) { (Object as any).assign(this, init); }
}

export enum ClaimOrigin
{
    Other = 'Other',
    Online = 'Online',
    Phone = 'Phone',
    Email = 'Email',
}

export class EstimationExpertSnoozeDto
{
    public comment?: string;
    public until: string;
    public disabled: boolean;
    public type: EstimationExpertSnoozeType;

    public constructor(init?: Partial<EstimationExpertSnoozeDto>) { (Object as any).assign(this, init); }
}

export class ColorMappingDto
{
    public username?: string;
    public hexColor?: string;

    public constructor(init?: Partial<ColorMappingDto>) { (Object as any).assign(this, init); }
}

export class ContractRemediationCheckListDto
{
    public id?: string;
    public contractNumber?: string;
    public contractPartner?: PartnerDto;
    public buildingAddress?: AddressDto;
    public damageRatio?: number;
    public claimCountSap?: number;
    public openClaimsCount: number;
    public noActionChecksCount: number;
    public createdAt: string;
    public assignedTo?: IdentityDto;
    public strainLevel: StrainLevel;
    public checkFinishedAt?: string;
    public attachmentName?: string;
    public decision?: ContractRemediationDecision;
    public warningMessageContent?: string;
    public reasonForNoAction?: string;
    public isClosed: boolean;
    public contractAdvisorFirstname?: string;
    public contractAdvisorLastname?: string;

    public constructor(init?: Partial<ContractRemediationCheckListDto>) { (Object as any).assign(this, init); }
}

export class ContractRemediationCheckResultDto
{
    public decision: ContractRemediationDecision;
    public checkFinishedAt: string;
    public attachmentName?: string;
    public warningMessageContent?: string;
    public reasonForNoAction?: string;
    public language?: string;

    public constructor(init?: Partial<ContractRemediationCheckResultDto>) { (Object as any).assign(this, init); }
}

export class ContractRemediationChecksSummaryDto
{
    public contractNumber?: string;
    public partner?: PartnerDto;
    public buildingAddress?: AddressDto;
    public checkFinishedAt: string;
    public warningChecksCount: number;
    public terminationChecksCount: number;
    public remediationOfferChecksCount: number;
    public noActionChecksCount: number;
    public contractAdvisorFirstname?: string;
    public contractAdvisorLastname?: string;

    public constructor(init?: Partial<ContractRemediationChecksSummaryDto>) { (Object as any).assign(this, init); }
}

export class ContractAddressDto
{
    public contractNumber?: string;
    public street?: string;
    public buildingNr?: string;
    public place?: string;

    public constructor(init?: Partial<ContractAddressDto>) { (Object as any).assign(this, init); }
}

export enum PaymentType
{
    NotSpecified = 'NotSpecified',
    Teilzahlung = 'Teilzahlung',
    Schlusszahlung = 'Schlusszahlung',
    Nachtragszahlung = 'Nachtragszahlung',
}

export enum PaymentBasisType
{
    NotSpecified = 'NotSpecified',
    Offerte = 'Offerte',
    Rechnung = 'Rechnung',
    Kalkulation = 'Kalkulation',
}

export class WorkingTypeDto
{
    public bkpNumber?: string;
    public id?: string;
    public parentId?: string;
    public name?: string;
    public frenchName?: string;
    public description?: string;
    public lifespan: number;
    public noAgeDeduction: boolean;
    public level: number;
    public sort: number;
    public hevPartIds?: string[];
    public searchTerms?: string[];

    public constructor(init?: Partial<WorkingTypeDto>) { (Object as any).assign(this, init); }
}

export class TaxAwareAmountDto
{
    public preInputTaxDeduction: number;
    public inputTaxDeduction: number;
    public value: number;

    public constructor(init?: Partial<TaxAwareAmountDto>) { (Object as any).assign(this, init); }
}

export class DeductionItemDto
{
    public amount: number;
    public remarks?: string;
    public covered: boolean;

    public constructor(init?: Partial<DeductionItemDto>) { (Object as any).assign(this, init); }
}

export class DeductionsDto
{
    public notInsured?: DeductionItemDto;
    public otherInsurance?: DeductionItemDto;
    public addedValue?: DeductionItemDto;
    public reducedValue?: DeductionItemDto;
    public overLimit?: DeductionItemDto;
    public ageDeduction?: DeductionItemDto;
    public overCurrentValue?: DeductionItemDto;
    public totalInvoiceDeductions: number;

    public constructor(init?: Partial<DeductionsDto>) { (Object as any).assign(this, init); }
}

export class PositionDto
{
    public id: string;
    public workingType?: WorkingTypeDto;
    public questionPartReplaced?: boolean;
    public questionHasDeductions?: boolean;
    public questionInputTaxDeduction?: boolean;
    public isWriteOff?: boolean;
    public valueAsNew?: number;
    public currentValue?: number;
    public amount?: number;
    public oldAmount?: number;
    public vatRate?: number;
    public coveredByGvb?: TaxAwareAmountDto;
    public coveredByPrivateAG?: TaxAwareAmountDto;
    public comment?: string;
    public age?: number;
    public deductions?: DeductionsDto;
    public coverageType: CoverageType;
    public activityType: ActivityType;

    public constructor(init?: Partial<PositionDto>) { (Object as any).assign(this, init); }
}

export class PaymentBasisDto
{
    public id: string;
    public title?: string;
    public type: PaymentBasisType;
    public comment?: string;
    public claimAttachments?: ClaimAttachmentDto[];
    public positions?: PositionDto[];

    public constructor(init?: Partial<PaymentBasisDto>) { (Object as any).assign(this, init); }
}

export class BeneficiaryDto
{
    public sourceId?: string;
    public paymentMethod?: PaymentMethod;
    public isCraftsman?: boolean;
    public iban?: string;
    public paymentReference?: string;
    public referenceNumber?: string;
    public esrAccountNumber?: string;
    public name?: string;
    public street?: string;
    public postalCode?: number;
    public place?: string;
    public useImageForPaymentInformation: boolean;

    public constructor(init?: Partial<BeneficiaryDto>) { (Object as any).assign(this, init); }
}

export class PaymentApprovalDto
{
    public executedBy?: IdentityDto;
    public executedAt?: string;
    public manual: boolean;

    public constructor(init?: Partial<PaymentApprovalDto>) { (Object as any).assign(this, init); }
}

export class InterestDto
{
    public coverageType: CoverageType;
    public activityType: ActivityType;
    public amount: number;

    public constructor(init?: Partial<InterestDto>) { (Object as any).assign(this, init); }
}

export class RetentionPositionDto
{
    public coverageType: CoverageType;
    public activityType: ActivityType;
    public amount: number;
    public isRefunded: boolean;

    public constructor(init?: Partial<RetentionPositionDto>) { (Object as any).assign(this, init); }
}

export class DeductionRefundDto
{
    public questionInputTaxDeduction?: boolean;
    public vatRate?: number;
    public amount?: TaxAwareAmountDto;

    public constructor(init?: Partial<DeductionRefundDto>) { (Object as any).assign(this, init); }
}

export class DeductionRefundPositionDto
{
    public coverageType: CoverageType;
    public activityType: ActivityType;
    public netAmountByGvb?: TaxAwareAmountDto;
    public netAmountByPrivateAg?: TaxAwareAmountDto;
    public fromDirectPaymentToBusiness: boolean;
    public positionId: string;

    public constructor(init?: Partial<DeductionRefundPositionDto>) { (Object as any).assign(this, init); }
}

export enum BusinessUnit
{
    GVB = 'GVB',
    PVAG = 'PVAG',
}

export class RegressPaymentInformationDto
{
    public amount: number;
    public esrReferenceNumber?: string;
    public esrAccountNumber?: string;
    public qrReferenceNumber?: string;
    public qrAccountNumber?: string;
    public createdAt: string;
    public businessUnit: BusinessUnit;

    public constructor(init?: Partial<RegressPaymentInformationDto>) { (Object as any).assign(this, init); }
}

export class PaymentDto
{
    public id: string;
    public type: PaymentType;
    public paymentBases?: PaymentBasisDto[];
    public beneficiary?: BeneficiaryDto;
    public estimationExpertApproval?: PaymentApprovalDto;
    public firstApproval?: PaymentApprovalDto;
    public firstApprovalAssignment?: IdentityDto;
    public secondApproval?: PaymentApprovalDto;
    public secondApprovalAssignment?: IdentityDto;
    public interests?: InterestDto[];
    public interest: number;
    public retentions?: RetentionPositionDto[];
    public deductionRefund?: DeductionRefundDto;
    public deductionRefunds?: DeductionRefundPositionDto[];
    public clientInteractions?: ClientInteractionDto[];
    public regressPaymentInformation?: RegressPaymentInformationDto;
    public sapError?: string;
    public createdAt: string;
    public ownedBy: SmartClaimsApp;

    public constructor(init?: Partial<PaymentDto>) { (Object as any).assign(this, init); }
}

export class ActivityTypeOverviewItemDto
{
    public activityType: ActivityType;
    public amount: number;

    public constructor(init?: Partial<ActivityTypeOverviewItemDto>) { (Object as any).assign(this, init); }
}

export class BenefitOverviewItemDto
{
    public coverageType: CoverageType;
    public activities?: ActivityTypeOverviewItemDto[];
    public amount?: number;

    public constructor(init?: Partial<BenefitOverviewItemDto>) { (Object as any).assign(this, init); }
}

export class BenefitOverviewDto
{
    public totalAmountGvb: number;
    public totalAmountPrivateAG: number;
    public gvbBenefitOverviewItem?: BenefitOverviewItemDto;
    public privateAGBenefitSummaryItems?: BenefitOverviewItemDto[];

    public constructor(init?: Partial<BenefitOverviewDto>) { (Object as any).assign(this, init); }
}

export class PaymentSummaryDto
{
    public paymentId?: string;
    public type: PaymentType;
    public isPaid: boolean;
    public totalAmount: number;
    public netAmount: number;
    public totalDeductionRefunds: number;
    public totalRetention: number;
    public totalInterest: number;
    public coveredAmount: number;
    public name?: string;
    public notInsuredDeductions: number;
    public otherInsuranceDeductions: number;
    public addedValueDeductions: number;
    public reducedValueDeductions: number;
    public overLimitDeductions: number;
    public ageDeductions: number;
    public inputTaxDeductions: number;
    public amountOverCurrentValue: number;
    public totalDeductions: number;
    public amountToCraftsman: number;
    public amountToCustomer: number;
    public paidAmount: number;
    public regressAmount: number;
    public benefitOverview?: BenefitOverviewDto;

    public constructor(init?: Partial<PaymentSummaryDto>) { (Object as any).assign(this, init); }
}

export class EmailClaimReportListItemDto
{
    public createdAt: string;
    public sender?: string;
    public subject?: string;
    public body?: string;
    public contractualPartner?: PartnerDto;
    public assignedTo?: IdentityDto;
    public id?: string;
    public contract?: ContractDto;
    public state: ClaimReportState;
    public claimSapId?: string;
    public claimId?: string;

    public constructor(init?: Partial<EmailClaimReportListItemDto>) { (Object as any).assign(this, init); }
}

export class MyGvbBuildingAddressDto
{
    public street?: string;
    public buildingNr?: string;
    public place?: string;
    public postalCode?: string;

    public constructor(init?: Partial<MyGvbBuildingAddressDto>) { (Object as any).assign(this, init); }
}

export class MyGvbContractDto
{
    public contractNumber?: string;
    public partnerNumber: number;
    public buildingAddress?: MyGvbBuildingAddressDto;
    public version: number;

    public constructor(init?: Partial<MyGvbContractDto>) { (Object as any).assign(this, init); }
}

export class MyGvbPartDto
{
    public name?: string;

    public constructor(init?: Partial<MyGvbPartDto>) { (Object as any).assign(this, init); }
}

export class MyGvbDocumentDto
{
    public fileName?: string;
    public displayName?: string;
    public thumbnailUrl?: string;
    public url?: string;
    public type?: string;

    public constructor(init?: Partial<MyGvbDocumentDto>) { (Object as any).assign(this, init); }
}

export class MyGvbIdentityDto
{
    public firstName?: string;
    public lastName?: string;
    public userName?: string;
    public phone?: string;
    public photoUrl?: string;

    public constructor(init?: Partial<MyGvbIdentityDto>) { (Object as any).assign(this, init); }
}

export class PhoneCallClaimReportListItemDto
{
    public id?: string;
    public claimId?: string;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public claimSapId?: string;
    public createdBy?: IdentityDto;
    public causeOfLoss?: string;
    public createdAt: string;
    public marked: boolean;
    public state: ClaimReportState;
    public contractNumber?: string;
    public partnerNumber?: number;

    public constructor(init?: Partial<PhoneCallClaimReportListItemDto>) { (Object as any).assign(this, init); }
}

export class CauseCountDto
{
    public cause?: string;
    public count: number;
    public year?: number;
    public month?: number;
    public dayOfYear?: number;
    public dateTime?: string;
    public fullCause?: CauseDto;

    public constructor(init?: Partial<CauseCountDto>) { (Object as any).assign(this, init); }
}

export class AreaCountDto
{
    public placeCode?: string;
    public place?: string;
    public total: number;
    public totalBasic?: number;
    public totalMedium?: number;

    public constructor(init?: Partial<AreaCountDto>) { (Object as any).assign(this, init); }
}

export class ClaimStatisticsDto
{
    public year?: number;
    public month?: number;
    public weekOfYear?: number;
    public dayOfYear?: number;
    public dateTime?: string;
    public count: number;
    public countCancelled: number;
    public countNew: number;
    public countInProgress: number;
    public countClosed: number;
    public countBasic: number;
    public countMedium: number;
    public countRepairRated: number;
    public repairRatingAverage: number;
    public repairRatingSum: number;
    public countReliabilityRated: number;
    public reliabilityAverage: number;
    public reliabilitySum: number;
    public countCommunicationRated: number;
    public communicationAverage: number;
    public communicationSum: number;
    public dailyKey?: string;
    public dailyKeyDisplay?: string;
    public weeklyKey?: string;
    public weeklyKeyDisplay?: string;
    public monthlyKey?: string;
    public monthlyKeyDisplay?: string;

    public constructor(init?: Partial<ClaimStatisticsDto>) { (Object as any).assign(this, init); }
}

export class NonAggregatedClaimStatisticsDto
{
    public year: number;
    public month: number;
    public weekOfYear: number;
    public dayOfYear: number;
    public date: string;
    public dailyTickDisplay?: string;
    public weeklyTickDisplay?: string;
    public monthlyTickDisplay?: string;
    public yearlyTickDisplay?: string;
    public dailyKey?: string;
    public weeklyKey?: string;
    public monthlyKey?: string;
    public yearlyKey?: string;
    public isBasic: boolean;
    public isMedium: boolean;
    public causeGroup?: string;

    public constructor(init?: Partial<NonAggregatedClaimStatisticsDto>) { (Object as any).assign(this, init); }
}

export class ClaimStatisticsByYearDto
{
    public year: number;
    public count: number;
    public countCancelled: number;
    public countNew: number;
    public countInProgress: number;
    public countClosed: number;

    public constructor(init?: Partial<ClaimStatisticsByYearDto>) { (Object as any).assign(this, init); }
}

export class EstimationExpertClaimCountDto
{
    public userId?: string;
    public totalClaims: number;

    public constructor(init?: Partial<EstimationExpertClaimCountDto>) { (Object as any).assign(this, init); }
}

export class LightningMeasurementDto
{
    public date: string;
    public hasLightning: boolean;

    public constructor(init?: Partial<LightningMeasurementDto>) { (Object as any).assign(this, init); }
}

export enum LightningCertificateStatus
{
    NotNeeded = 'NotNeeded',
    Required = 'Required',
    InProgress = 'InProgress',
    Done = 'Done',
    Error = 'Error',
    TimedOut = 'TimedOut',
}

export class LightningQueryDto
{
    public startDate: string;
    public endDate: string;
    public measurements?: LightningMeasurementDto[];
    public certificateStatus: LightningCertificateStatus;
    public createdAt: string;
    public createdBy?: IdentityDto;

    public constructor(init?: Partial<LightningQueryDto>) { (Object as any).assign(this, init); }
}

export class EmailValidationResultDto
{
    public emailAddress?: string;
    public valid: boolean;
    public validationMessage?: string;

    public constructor(init?: Partial<EmailValidationResultDto>) { (Object as any).assign(this, init); }
}

export class AbsenceDto
{
    public userId?: string;
    public start: string;
    public end: string;

    public constructor(init?: Partial<AbsenceDto>) { (Object as any).assign(this, init); }
}

export class AreaDto
{
    public id?: string;
    public estimationExpertCircle?: number;
    public placeCode?: string;
    public place?: string;
    public district?: string;
    public automaticAssignmentPaused: boolean;
    public totalClaims: number;
    public unassignedClaims: number;
    public activeEstimationExpertUsernames?: string[];

    public constructor(init?: Partial<AreaDto>) { (Object as any).assign(this, init); }
}

export class ClaimActivity
{
    public claims?: UsersOnClaimDto[];

    public constructor(init?: Partial<ClaimActivity>) { (Object as any).assign(this, init); }
}

export class GetClaimAttachmentsResponse
{
    public claimAttachments?: ClaimAttachmentDto[];

    public constructor(init?: Partial<GetClaimAttachmentsResponse>) { (Object as any).assign(this, init); }
}

export class PagedResult<ClaimMetadataDto>
{
    public page: number;
    public pageSize: number;
    public totalResults: number;
    public results?: ClaimMetadataDto[];

    public constructor(init?: Partial<PagedResult<ClaimMetadataDto>>) { (Object as any).assign(this, init); }
}

export class ClaimReportDto
{
    public id?: string;
    public claimId?: string;
    public claimReportOrigin: ClaimReportOrigin;
    public damageOccuredAt?: string;
    public damageType: DamageType;
    public perpetrator?: PerpetratorDto;
    public parts?: PartDto[];
    public causeOfLoss?: string;
    public building?: BuildingDto;
    public address?: string;
    public contractPartner?: ContractPartnerDto;
    public role: CustomerType;
    public otherRole?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public messageChannel?: ClaimMessageChannel;
    public repairWorkState: RepairWorkState;
    public iban?: string;
    public ibanOwnerName?: string;
    public assistanceFavored: boolean;
    public email?: string;
    public mobile?: string;
    public phone?: string;
    public state: ClaimReportState;
    public createdAt: string;
    public triageDecision?: TriageDecisionDto;
    public contract?: ContractDto;
    public preferredCraftsman?: CraftsmanDto;
    public preferredCraftsmanSearch?: string;
    public language?: string;
    public reportedByFirstName?: string;
    public reportedByLastName?: string;
    public assignedTo?: IdentityDto;
    public createdBy?: IdentityDto;

    public constructor(init?: Partial<ClaimReportDto>) { (Object as any).assign(this, init); }
}

export class RegressAuditDto
{
    public activities?: RegressActivityDto[];
    public isFinished: boolean;

    public constructor(init?: Partial<RegressAuditDto>) { (Object as any).assign(this, init); }
}

export class NaturalHazardsPreventionNotificationDto
{
    public generalInterest: boolean;
    public hailProtectionInterest: boolean;
    public centralBlindsControl: boolean;
    public note?: string;
    public origin: SmartClaimsApp;
    public createdAt: string;
    public createdBy?: IdentityDto;

    public constructor(init?: Partial<NaturalHazardsPreventionNotificationDto>) { (Object as any).assign(this, init); }
}

export class ClaimDto
{
    public id?: string;
    public claimSapId?: string;
    public origin: ClaimOrigin;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public messageChannel: ClaimMessageChannel;
    public email?: string;
    public phone?: string;
    public mobile?: string;
    public language?: string;
    public repairWorkState: RepairWorkState;
    public perpetrator?: PerpetratorDto;
    public customerType: CustomerType;
    public otherRole?: string;
    public paymentInformation?: PaymentInformationDto;
    public parts?: PartDto[];
    public snooze?: SnoozeDto;
    public clientInteractions?: ClientInteractionDto[];
    public openIssueActions?: OpenIssueActionDto[];
    public triageDecision?: TriageDecisionDto;
    public claimReportLinks?: ClaimReportLinkDto[];
    public hasPendingNachtragCheck: boolean;
    public hasNachtragPaymentsWithFirstApproval: boolean;
    public regressAudits?: RegressAuditDto[];
    public damageOccuredAt: string;
    public deadlineExtensionExpiresAt?: string;
    public reminderDeadlineExpiresAt?: string;
    public contractNumber?: string;
    public contract?: ContractDto;
    public status: ClaimStatus;
    public isCancelled: boolean;
    public backOfficeComment?: string;
    public backOfficeAssignee?: IdentityDto;
    public backOfficeClosure?: ClosureDto;
    public inClarificationByBackOfficeAssignee: boolean;
    public mediumProcessingType: MediumProcessingType;
    public estimationExpertComment?: string;
    public estimationExpertAssignmentComment?: string;
    public estimationExpertAssignee?: IdentityDto;
    public estimationExpertClosure?: ClosureDto;
    public estimationExpertSnooze?: EstimationExpertSnoozeDto;
    public estimationExpertOpenIssueAction?: EstimationExpertOpenIssueActionDto;
    public orderedPrejudiceChecks?: PrejudiceCheckDto[];
    public inspections?: InspectionDto[];
    public inspectionNeeded: boolean;
    public craftsmanAssignments?: CraftsmanAssignmentDto[];
    public craftsmanAppointment?: string;
    public craftsmanFixingAppointment?: string;
    public caseHandlerRead: boolean;
    public hasNewDocument: boolean;
    public suggestedBy: ClaimSuggestedBy;
    public suggestedByComment?: string;
    public craftsmanJob?: CraftsmanJobDto;
    public contractChangeRequestByEstimationExpert?: ContractChangeRequestDto;
    public token?: string;
    public createdAt: string;
    public lastUpdatedAt: string;
    public lastUpdatedBy?: IdentityDto;
    public naturalHazardsPreventionNotification?: NaturalHazardsPreventionNotificationDto;

    public constructor(init?: Partial<ClaimDto>) { (Object as any).assign(this, init); }
}

export class ClaimAssignmentDto
{
    public backOfficeAssignee?: IdentityDto;
    public estimationExpertAssignee?: IdentityDto;

    public constructor(init?: Partial<ClaimAssignmentDto>) { (Object as any).assign(this, init); }
}

export class ContractRemediationCheckDto
{
    public id?: string;
    public contractNumber?: string;
    public claimId?: string;
    public assignee?: IdentityDto;
    public result?: ContractRemediationCheckResultDto;
    public contractPartner?: PartnerDto;
    public buildingAddress?: AddressDto;
    public damageRatio?: number;
    public createdAt: string;
    public createdBy?: IdentityDto;
    public lastUpdatedAt: string;
    public lastUpdatedBy?: IdentityDto;
    public remediationDate?: string;
    public isRemediated: boolean;
    public strainLevel: StrainLevel;

    public constructor(init?: Partial<ContractRemediationCheckDto>) { (Object as any).assign(this, init); }
}

export class ContractRemediationCheckStatisticsDto
{
    public totalNumberOfUnassignedChecksExceeded: number;
    public totalNumberOfUnassignedChecksWarning: number;

    public constructor(init?: Partial<ContractRemediationCheckStatisticsDto>) { (Object as any).assign(this, init); }
}

export class CustomerDto
{
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public phone?: string;
    public mobile?: string;
    public iban?: string;
    public address?: AddressDto;

    public constructor(init?: Partial<CustomerDto>) { (Object as any).assign(this, init); }
}

export class CraftsmanUsageDto
{
    public numberOfClaims: number;

    public constructor(init?: Partial<CraftsmanUsageDto>) { (Object as any).assign(this, init); }
}

export class D41Dto
{
    public claimId?: string;
    public contract?: ContractDto;
    public payments?: PaymentDto[];
    public unassignedPaymentBases?: PaymentBasisDto[];
    public retentions?: RetentionPositionDto[];
    public openDeductionRefunds?: DeductionRefundPositionDto[];
    public clientInteractions?: ClientInteractionDto[];
    public damageByCoverages?: DamageByCoverageDto[];
    public lastUpdatedAt: string;
    public lastUpdatedBy?: IdentityDto;
    public changeVector?: string;

    public constructor(init?: Partial<D41Dto>) { (Object as any).assign(this, init); }
}

export class D41SummaryDto
{
    public id?: string;
    public d41Id?: string;
    public aggregatedPaymentSummary?: PaymentSummaryDto;
    public benefitOverview?: BenefitOverviewDto;
    public paymentSummaries?: PaymentSummaryDto[];
    public totalAlreadyPaid: number;
    public totalGrossAmount: number;
    public totalDeductions: number;
    public totalNetAmount: number;
    public totalDeductionRefunds: number;
    public totalRetention: number;
    public totalCoveredAmount: number;
    public totalDamageAmount: number;

    public constructor(init?: Partial<D41SummaryDto>) { (Object as any).assign(this, init); }
}

export class PaymentApprovalsUpdateDto
{
    public paymentId: string;
    public firstApproval?: PaymentApprovalDto;
    public secondApproval?: PaymentApprovalDto;
    public isSample: boolean;

    public constructor(init?: Partial<PaymentApprovalsUpdateDto>) { (Object as any).assign(this, init); }
}

export class SecondApprovalAssignmentChangedDto
{
    public paymentId: string;
    public newAssignee?: IdentityDto;

    public constructor(init?: Partial<SecondApprovalAssignmentChangedDto>) { (Object as any).assign(this, init); }
}

export class EmailClaimReportDto
{
    public id?: string;
    public claimId?: string;
    public damageOccuredAt?: string;
    public parts?: PartDto[];
    public causeOfLoss?: string;
    public perpetrator?: PerpetratorDto;
    public role: CustomerType;
    public otherRole?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public messageChannel?: ClaimMessageChannel;
    public iban?: string;
    public ibanOwnerName?: string;
    public email?: string;
    public mobile?: string;
    public phone?: string;
    public state: ClaimReportState;
    public createdAt: string;
    public triageDecision?: TriageDecisionDto;
    public contract?: ContractDto;
    public preferredCraftsman?: CraftsmanDto;
    public preferredCraftsmanSearch?: string;
    public language?: string;
    public assignedTo?: IdentityDto;
    public sender?: string;
    public subject?: string;
    public bodyPlain?: string;

    public constructor(init?: Partial<EmailClaimReportDto>) { (Object as any).assign(this, init); }
}

export class HealthCheckResult
{
    public apiHealthy: boolean;

    public constructor(init?: Partial<HealthCheckResult>) { (Object as any).assign(this, init); }
}

export class MyGvbClaimDto
{
    public id?: string;
    public claimSapId?: string;
    public contract?: MyGvbContractDto;
    public damageOccurredAt: string;
    public causeDescription?: string;
    public parts?: MyGvbPartDto[];
    public status?: string;
    public processingType?: string;
    public chatUrl?: string;
    public claimOverviewUrl?: string;
    public documents?: MyGvbDocumentDto[];
    public messageChannel?: string;
    public repairWorkState?: string;
    public estimationExpert?: MyGvbIdentityDto;
    public backOfficeAssignee?: MyGvbIdentityDto;

    public constructor(init?: Partial<MyGvbClaimDto>) { (Object as any).assign(this, init); }
}

export class PhoneCallClaimReportDto
{
    public id?: string;
    public claimId?: string;
    public damageOccuredAt?: string;
    public parts?: PartDto[];
    public causeOfLoss?: string;
    public perpetrator?: PerpetratorDto;
    public role: CustomerType;
    public otherRole?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public messageChannel?: ClaimMessageChannel;
    public iban?: string;
    public ibanOwnerName?: string;
    public email?: string;
    public mobile?: string;
    public phone?: string;
    public state: ClaimReportState;
    public createdAt: string;
    public triageDecision?: TriageDecisionDto;
    public contract?: ContractDto;
    public preferredCraftsman?: CraftsmanDto;
    public preferredCraftsmanSearch?: string;
    public language?: string;
    public assignedTo?: IdentityDto;
    public marked: boolean;
    public triageOnDraft: boolean;
    public repairWorkState: RepairWorkState;
    public assistanceFavored: boolean;
    public estimationExpertUsername?: string;
    public updatedAt: string;
    public createdBy?: IdentityDto;

    public constructor(init?: Partial<PhoneCallClaimReportDto>) { (Object as any).assign(this, init); }
}

export class ClaimOverviewStatsDto
{
    public claimCount: number;
    public closedClaimCount: number;
    public messagesCount: number;
    public feedbackCount: number;
    public cancelledClaimCount: number;
    public feedbackOpenCount: number;

    public constructor(init?: Partial<ClaimOverviewStatsDto>) { (Object as any).assign(this, init); }
}

export class ProcessingTypeCountDto
{
    public assistanceCount: number;
    public payFastCount: number;
    public payByDocumentCount: number;
    public refusalCount: number;
    public smartMediumCount: number;

    public constructor(init?: Partial<ProcessingTypeCountDto>) { (Object as any).assign(this, init); }
}

export class IncludeInGenerationForClients implements IReturn<IncludeInGenerationForClients>
{
    public badRequestErrorCode: BadRequestErrorCode;

    public constructor(init?: Partial<IncludeInGenerationForClients>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IncludeInGenerationForClients'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IncludeInGenerationForClients(); }
}

export class UserDto
{
    public id?: string;
    public firstName?: string;
    public lastName?: string;
    public userName?: string;
    public email?: string;
    public company?: string;
    public phoneNumber?: string;
    public roles?: string[];
    public isEstimationExpert: boolean;
    public hasInvitationPending: boolean;
    public dontShowProfilePicture: boolean;
    public isSenior: boolean;

    public constructor(init?: Partial<UserDto>) { (Object as any).assign(this, init); }
}

export class CreateAccountResponse
{
    public token?: string;

    public constructor(init?: Partial<CreateAccountResponse>) { (Object as any).assign(this, init); }
}

export class LightningExpertiseDto
{
    public id?: string;
    public claimId?: string;
    public claimReportId?: string;
    public queries?: LightningQueryDto[];
    public createdAt: string;
    public lastUpdatedAt: string;
    public createdBy?: IdentityDto;
    public lastUpdatedBy?: IdentityDto;

    public constructor(init?: Partial<LightningExpertiseDto>) { (Object as any).assign(this, init); }
}

export class WebFormClaimReportDto
{
    public id?: string;
    public correlationId?: string;
    public claimId?: string;
    public damageOccuredAt?: string;
    public damageType: DamageType;
    public waterOrigin?: WaterOrigin;
    public pest?: Pest;
    public vandalismType?: VandalismType;
    public perpetrator?: PerpetratorDto;
    public parts?: PartDto[];
    public causeOfLoss?: string;
    public building?: BuildingDto;
    public address?: string;
    public contractPartner?: ContractPartnerDto;
    public role: CustomerType;
    public otherRole?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public repairWorkState: RepairWorkState;
    public iban?: string;
    public ibanOwnerName?: string;
    public assistanceFavored: boolean;
    public email?: string;
    public emailVerification?: string;
    public mobile?: string;
    public phone?: string;
    public messageChannel?: ClaimMessageChannel;
    public state: ClaimReportState;
    public createdAt: string;
    public triageDecision?: TriageDecisionDto;
    public contract?: ContractDto;
    public preferredCraftsman?: CraftsmanLookupDto;
    public preferredCraftsmanSearch?: string;
    public language?: string;
    public assignedTo?: IdentityDto;
    public reportedBy?: ReporterDto;

    public constructor(init?: Partial<WebFormClaimReportDto>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId?: string;

    // @DataMember(Order=2)
    public sessionId?: string;

    // @DataMember(Order=3)
    public userName?: string;

    // @DataMember(Order=4)
    public displayName?: string;

    // @DataMember(Order=5)
    public referrerUrl?: string;

    // @DataMember(Order=6)
    public bearerToken?: string;

    // @DataMember(Order=7)
    public refreshToken?: string;

    // @DataMember(Order=8)
    public profileUrl?: string;

    // @DataMember(Order=9)
    public roles?: string[];

    // @DataMember(Order=10)
    public permissions?: string[];

    // @DataMember(Order=11)
    public responseStatus?: ResponseStatus;

    // @DataMember(Order=12)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

export class ServerSentEventTypes
{
    public messageSmsStatusUpdate?: MessageSmsStatusUpdateDto;
    public customerResponseNotification?: CustomerResponseNotification;
    public smsStatusUpdate?: SmsStatusUpdate;
    public claimRegistrationAssignmentChanged?: ClaimRegistrationAssignmentChangedDto;
    public claimAssignmentChanged?: ClaimAssignmentChangedDto;
    public craftsmanJobEmailStatusUpdate?: CraftsmanJobEmailStatusUpdate;
    public claimWithLastMessage?: ClaimWithLastMessageDto;
    public secondApprovalAssignmentChanged?: SecondApprovalAssignmentChangedDto;
    public usersLiveState?: ClaimActivity;

    public constructor(init?: Partial<ServerSentEventTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ServerSentEventTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class UnknownTypes
{
    public claimList?: ClaimListDto[];
    public d41List?: D41Dto[];
    public craftsmanList?: CraftsmanDto[];
    public claimRegistrationList?: WebFormClaimReportDto[];
    public claimAttachmentList?: ClaimAttachmentDto[];
    public messageList?: MessageDto[];
    public metadata?: ClaimMetadataDto[];

    public constructor(init?: Partial<UnknownTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnknownTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/email-validations/{email}", "GET")
export class GetEmailValidation implements IReturn<EmailValidationResultDto>
{
    public email?: string;
    public revalidate: boolean;

    public constructor(init?: Partial<GetEmailValidation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailValidation'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EmailValidationResultDto(); }
}

// @Route("/email-validations/{email}", "PUT")
export class WhitelistEmail implements IReturn<EmailValidationResultDto>
{
    public email?: string;

    public constructor(init?: Partial<WhitelistEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WhitelistEmail'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new EmailValidationResultDto(); }
}

// @Route("/webhooks/mailgun/event", "POST")
export class MailgunWebhookMessageRequest implements IReturnVoid, IMailgunWebhookRequest
{
    public signature?: MailgunWebhookSignature;
    // @DataMember(Name="event-data")
    public event_data?: MailgunWebhookEventData;

    public constructor(init?: Partial<MailgunWebhookMessageRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MailgunWebhookMessageRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/mailgun/route/message", "POST")
export class MailgunRouteMessageRequest implements IReturnVoid
{
    // @DataMember(Name="Message-Id")
    public Message_Id?: string;

    public signature?: string;
    public token?: string;
    public timestamp: number;
    public subject?: string;
    public from?: string;
    public to?: string;
    public cc?: string;
    // @DataMember(Name="In-Reply-To")
    public In_Reply_To?: string;

    public references?: string;
    // @DataMember(Name="body-plain")
    public body_plain?: string;

    // @DataMember(Name="stripped-text")
    public stripped_text?: string;

    // @DataMember(Name="stripped-signature")
    public stripped_signature?: string;

    // @DataMember(Name="body-html")
    public body_html?: string;

    // @DataMember(Name="stripped-html")
    public stripped_html?: string;

    // @DataMember(Name="content-id-map")
    public content_id_map?: string;

    public constructor(init?: Partial<MailgunRouteMessageRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MailgunRouteMessageRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/absences/{UserId*}", "GET")
export class GetAbsence implements IReturn<AbsenceDto>
{
    public userId?: string;

    public constructor(init?: Partial<GetAbsence>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAbsence'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AbsenceDto(); }
}

// @Route("/absences/{UserId*}", "PUT")
export class UpdateAbsence implements IReturnVoid
{
    public userId?: string;
    public start: string;
    public end: string;

    public constructor(init?: Partial<UpdateAbsence>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateAbsence'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/absences/{UserId*}", "DELETE")
export class DeleteAbsence implements IReturnVoid
{
    public userId?: string;

    public constructor(init?: Partial<DeleteAbsence>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteAbsence'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/areas/{Id}", "GET")
export class GetArea implements IReturn<AreaDto>
{
    public id?: string;

    public constructor(init?: Partial<GetArea>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetArea'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AreaDto(); }
}

// @Route("/areas", "GET")
export class GetAreas implements IReturn<AreaDto[]>
{
    public areaIds?: string[];
    public query?: string;
    public estimationExpertCircles?: number[];

    public constructor(init?: Partial<GetAreas>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAreas'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AreaDto>(); }
}

// @Route("/areas/{AreaId}", "PUT")
export class UpdateAreaAutoAssignment implements IReturnVoid
{
    public areaId?: string;
    public automaticAssignmentPaused: boolean;

    public constructor(init?: Partial<UpdateAreaAutoAssignment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateAreaAutoAssignment'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/payments/{PaymentId}/beneficiary", "PUT")
export class UpdateBeneficiary implements IReturnVoid
{
    public claimId?: string;
    public paymentId: string;
    public isCraftsman?: boolean;
    public name?: string;
    public street?: string;
    public postalCode?: number;
    public place?: string;
    public paymentMethod: PaymentMethod;
    public iban?: string;
    public paymentReference?: string;
    public referenceNumber?: string;
    public esrAccountNumber?: string;

    public constructor(init?: Partial<UpdateBeneficiary>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateBeneficiary'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/benefits", "GET")
export class GetBenefits implements IReturn<BenefitDto[]>
{

    public constructor(init?: Partial<GetBenefits>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBenefits'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<BenefitDto>(); }
}

// @Route("/causes", "GET")
export class GetCauses implements IReturn<CauseDto[]>
{
    public type: CauseType;

    public constructor(init?: Partial<GetCauses>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCauses'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CauseDto>(); }
}

// @Route("/user-activity", "GET")
export class GetUserActivity implements IReturn<ClaimActivity>
{

    public constructor(init?: Partial<GetUserActivity>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserActivity'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClaimActivity(); }
}

// @Route("/internal/publish-user-state")
export class PublishUserStateInternal implements IReturnVoid
{

    public constructor(init?: Partial<PublishUserStateInternal>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PublishUserStateInternal'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/claim-reports/{ClaimReportOrigin}/{ClaimReportId}/attachments", "GET")
// @Route("/claims/{ClaimId}/attachments", "GET")
export class GetClaimAttachments extends OrderedRequest implements IReturn<GetClaimAttachmentsResponse>, IClaimAware
{
    public claimId?: string;
    public claimReportId?: string;
    public claimReportOrigin: ClaimReportOrigin;
    public craftsmanId?: string;
    /** @description Filter attachments by type */
    // @ApiMember(Description="Filter attachments by type")
    public type?: ClaimAttachmentType;

    public changedSince?: string;
    public paymentBasisId?: string;

    public constructor(init?: Partial<GetClaimAttachments>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimAttachments'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetClaimAttachmentsResponse(); }
}

// @Route("/attachments/{Id}", "GET")
export class GetClaimAttachment implements IReturn<ClaimAttachmentDto>
{
    public id?: string;

    public constructor(init?: Partial<GetClaimAttachment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimAttachment'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClaimAttachmentDto(); }
}

// @Route("/claims/{ClaimId}/attachments", "POST")
export class UploadClaimAttachment implements IReturn<ClaimAttachmentDto>
{
    public claimId?: string;
    public name?: string;
    public type: ClaimAttachmentType;
    public assignedType: IdentityType;

    public constructor(init?: Partial<UploadClaimAttachment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadClaimAttachment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClaimAttachmentDto(); }
}

// @Route("/claim-attachments/{ClaimAttachmentId}", "GET")
export class GetClaimAttachmentContent implements IReturn<Blob>
{
    public claimAttachmentId?: string;
    public preview: boolean;
    public inline: boolean;
    public apiKey?: string;

    public constructor(init?: Partial<GetClaimAttachmentContent>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimAttachmentContent'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/claim-attachments/{Id}/content", "POST")
export class UpdateClaimAttachmentContent implements IReturn<ClaimAttachmentDto>
{
    public id?: string;

    public constructor(init?: Partial<UpdateClaimAttachmentContent>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateClaimAttachmentContent'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClaimAttachmentDto(); }
}

// @Route("/claim-attachments/{Id}", "PUT")
export class UpdateClaimAttachment implements IReturn<ClaimAttachmentDto>
{
    public id?: string;
    public craftsmanId?: string;
    public title?: string;
    public type: ClaimAttachmentType;
    public assignedType: IdentityType;
    public relevant: boolean;
    public quoteChecked?: boolean;

    public constructor(init?: Partial<UpdateClaimAttachment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateClaimAttachment'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new ClaimAttachmentDto(); }
}

// @Route("/claims/{ClaimId}/image", "GET")
export class GetClaimPreview implements IReturn<Blob>, IClaimAware
{
    public claimId?: string;

    public constructor(init?: Partial<GetClaimPreview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimPreview'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/claim-attachments/{Id}", "DELETE")
export class DeleteClaimAttachment implements IReturnVoid
{
    public id?: string;

    public constructor(init?: Partial<DeleteClaimAttachment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteClaimAttachment'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/claim-attachments/{Id}/usages", "GET")
export class GetClaimAttachmentUsage implements IReturn<ClaimAttachmentUsageDto[]>
{
    public id?: string;

    public constructor(init?: Partial<GetClaimAttachmentUsage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimAttachmentUsage'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ClaimAttachmentUsageDto>(); }
}

// @Route("/claims/{ClaimId}/basis-documents", "Patch")
export class PatchBasisDocuments implements IClaimAware
{
    public claimId?: string;

    public constructor(init?: Partial<PatchBasisDocuments>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchBasisDocuments'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() {}
}

// @Route("/reports/claims")
export class GetClaimExcelReport implements IReturn<Blob>
{

    public constructor(init?: Partial<GetClaimExcelReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimExcelReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/reports/payments")
export class GetPaymentExcelReport implements IReturn<Blob>
{

    public constructor(init?: Partial<GetPaymentExcelReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPaymentExcelReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/claim-metadata", "GET")
export class GetClaimMetadata extends PagedRequest implements IReturn<PagedResult<ClaimMetadataDto>>
{
    public estimationExpertAssigneeUserId?: string;
    public excludeClosedByEstimationExpert: boolean;
    public excludeClosedByEstimationExpertMoreThanXDaysAgo?: number;

    public constructor(init?: Partial<GetClaimMetadata>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<ClaimMetadataDto>(); }
}

// @Route("/claim-reports/{Origin}/{Id}", "GET")
export class GetClaimReport implements IReturn<ClaimReportDto>
{
    public id?: string;
    public origin: ClaimReportOrigin;
    public responseStatus?: ResponseStatus;

    public constructor(init?: Partial<GetClaimReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClaimReportDto(); }
}

// @Route("/claim-reports", "GET")
export class GetClaimReports extends PagedAndOrderedRequest implements IReturn<PagedResult<ClaimReportListItemDto>>
{
    public query?: string;
    public state?: ClaimReportState[];
    public assistanceFavored?: boolean;
    public assignedTo?: string[];
    public causeGroups?: string[];
    public from?: string;
    public to?: string;

    public constructor(init?: Partial<GetClaimReports>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimReports'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<ClaimReportListItemDto>(); }
}

// @Route("/claim-reports/{Origin}/{Id}", "PUT")
export class UpdateClaimReport implements IReturnVoid
{
    public id?: string;
    public origin: ClaimReportOrigin;
    public role: CustomerType;
    public otherRole?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public repairWorkState: RepairWorkState;
    public iban?: string;
    public ibanOwnerName?: string;
    public assistanceFavored: boolean;
    public email?: string;
    public mobile?: string;
    public phone?: string;
    public contract?: ContractDto;
    public language?: string;

    public constructor(init?: Partial<UpdateClaimReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateClaimReport'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/internal/update-triage")
export class UpdateTriageInternal implements IReturn<ClaimReportDto>, IPost, IUpdateTriage
{
    public id?: string;
    public origin: ClaimReportOrigin;
    public claimSapId?: string;
    public amountCovered?: number;
    public processingType: ProcessingType;
    public cause?: CauseDto;
    public perpetrator?: PerpetratorDto;
    public contract?: ContractDto;
    public damageOccuredAt: string;
    public comment?: string;
    public craftsmanId?: string;
    public ignoreSapWarning: boolean;
    public ignoreAssignment: boolean;
    public messageChannel?: ClaimMessageChannel;
    public email?: string;
    public mobile?: string;
    public estimationExpertUsername?: string;
    public isAlreadyRecordedInSap: boolean;
    public duplicateId?: string;
    public suppressDuplicateReview: boolean;

    public constructor(init?: Partial<UpdateTriageInternal>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTriageInternal'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClaimReportDto(); }
}

// @Route("/claim-reports/{Origin}/{Id}/triage", "POST")
export class UpdateTriage implements IReturn<ClaimReportDto>, IPost, IUpdateTriage
{
    public id?: string;
    public origin: ClaimReportOrigin;
    public claimSapId?: string;
    public amountCovered?: number;
    public processingType: ProcessingType;
    public cause?: CauseDto;
    public perpetrator?: PerpetratorDto;
    public contract?: ContractDto;
    public damageOccuredAt: string;
    public comment?: string;
    public craftsmanId?: string;
    public ignoreSapWarning: boolean;
    public ignoreAssignment: boolean;
    public messageChannel?: ClaimMessageChannel;
    public email?: string;
    public mobile?: string;
    public estimationExpertUsername?: string;
    public isAlreadyRecordedInSap: boolean;
    public duplicateId?: string;
    public suppressDuplicateReview: boolean;

    public constructor(init?: Partial<UpdateTriage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTriage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClaimReportDto(); }
}

// @Route("/claim-reports/{Origin}/{Id}/assignments/me", "PUT")
export class AssignClaimReportToMe implements IReturn<ClaimReportDto>
{
    public id?: string;
    public origin: ClaimReportOrigin;
    public force: boolean;

    public constructor(init?: Partial<AssignClaimReportToMe>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignClaimReportToMe'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new ClaimReportDto(); }
}

// @Route("/claim-reports/{Origin}/{Id}/assignments/me", "DELETE")
export class UnassignMeFromClaimRegistration implements IReturn<ClaimReportDto>
{
    public id?: string;
    public origin: ClaimReportOrigin;

    public constructor(init?: Partial<UnassignMeFromClaimRegistration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnassignMeFromClaimRegistration'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClaimReportDto(); }
}

// @Route("/claim-reports/{Origin}/{Id}", "DELETE")
export class DeleteClaimReport implements IReturnVoid
{
    public id?: string;
    public origin: ClaimReportOrigin;

    public constructor(init?: Partial<DeleteClaimReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteClaimReport'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/correlations", "GET")
export class GetCorrelations implements IReturn<CorrelationDto[]>
{
    public contractNumber?: string;
    public damageOccuredAt: string;

    public constructor(init?: Partial<GetCorrelations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCorrelations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CorrelationDto>(); }
}

// @Route("/claims", "POST")
export class CreateClaim implements IReturn<ClaimDto>
{
    public claimSapId?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public cause?: CauseDto;
    public perpetrator?: PerpetratorDto;
    public mobile?: string;
    public phone?: string;
    public damageOccuredAt: string;
    public comment?: string;
    public origin?: string;
    public customerType: CustomerType;
    public otherRole?: string;
    public company?: string;
    public parts?: PartDto[];
    public messageChannel: ClaimMessageChannel;
    public language?: string;
    public processingType: ProcessingType;
    public paymentInformation?: PaymentInformationDto;
    public contractNumber: number;
    public estimationExpertUsername?: string;

    public constructor(init?: Partial<CreateClaim>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateClaim'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClaimDto(); }
}

// @Route("/claims", "GET")
export class GetClaims extends PagedAndOrderedRequest implements IReturn<PagedResult<ClaimListDto>>
{
    /** @description Search query for filtering claims by .... */
    // @ApiMember(DataType="string", Description="Search query for filtering claims by ....", ParameterType="query", Verb="GET")
    public query?: string;

    /** @description Search claim by contract id .... */
    // @ApiMember(DataType="string", Description="Search claim by contract id ....", ParameterType="query", Verb="GET")
    public contractNumber?: string;

    /** @description Search claim by SapId */
    // @ApiMember(DataType="string", Description="Search claim by SapId", ParameterType="query", Verb="GET")
    public sapId?: string;

    /** @description Filter by status (, separated) */
    // @ApiMember(DataType="string", Description="Filter by status (, separated)", ParameterType="query", Verb="GET")
    public status?: ClaimStatus[];

    /** @description Filter by processing types (, separated) */
    // @ApiMember(DataType="string", Description="Filter by processing types (, separated)", ParameterType="query", Verb="GET")
    public processingTypes?: ProcessingType[];

    /** @description Search claims by craftsman */
    // @ApiMember(DataType="integer", Description="Search claims by craftsman", ParameterType="query", Verb="GET")
    public craftsmanId?: string;

    /** @description Work Scope */
    // @ApiMember(DataType="string", Description="Work Scope", ParameterType="query", Verb="GET")
    public filterSet?: FilterSet;

    /** @description Subfilter for FilterSet.FollowUps */
    // @ApiMember(Description="Subfilter for FilterSet.FollowUps", ParameterType="query", Verb="GET")
    public followUpFilter: FollowUpFilter;

    /** @description Filter by assigned user */
    // @ApiMember(DataType="string", Description="Filter by assigned user", ParameterType="query", Verb="GET")
    public assignedTo?: string[];

    /** @description Filter by estimation expert assignee */
    // @ApiMember(Description="Filter by estimation expert assignee", ParameterType="query", Verb="GET")
    public estimationExpertAssignee?: string[];

    public openIssueActions?: OpenIssueAction[];
    public regressAuditOpenIssueActions?: RegressAction[];
    public changedSince?: string;
    public estimationExpertCircles?: number[];
    public causeGroups?: string[];
    public from?: string;
    public to?: string;
    public areaNorthWest?: number[];
    public areaWidth: number;
    public areaHeight: number;
    public queryByArea?: string;
    public queryByEstimationExpert?: string;
    public isCancelled: boolean;
    public onlyUnassigned: boolean;
    public estimationExpertAssigned?: boolean;
    public onlyPurraCita?: boolean;
    public excludeOwnFirstApprovals: boolean;
    public excludeInClarificationByBackOfficeAssignee: boolean;
    public excludeReactivated: boolean;
    public excludeClosedByEstimationExpert: boolean;
    public free?: boolean;
    public skipList?: string[];

    public constructor(init?: Partial<GetClaims>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaims'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<ClaimListDto>(); }
}

// @Route("/claims/unread", "GET")
export class GetUnreadClaims extends PagedAndOrderedRequest implements IReturn<PagedResult<ClaimWithLastMessageDto>>
{

    public constructor(init?: Partial<GetUnreadClaims>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUnreadClaims'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<ClaimWithLastMessageDto>(); }
}

// @Route("/claims/sms-not-delivered")
export class GetClaimsWithSmsError extends PagedAndOrderedRequest implements IReturn<PagedResult<ClaimWithLastMessageDto>>
{

    public constructor(init?: Partial<GetClaimsWithSmsError>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimsWithSmsError'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<ClaimWithLastMessageDto>(); }
}

// @Route("/claims/{ClaimId}", "GET")
export class GetClaim implements IReturn<ClaimDto>, IClaimAware
{
    /** @description Claim id */
    // @ApiMember(DataType="long", Description="Claim id", Name="ClaimId", ParameterType="path", Verb="GET")
    public claimId?: string;

    public constructor(init?: Partial<GetClaim>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaim'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClaimDto(); }
}

// @Route("/claims/{Id}", "PUT")
export class UpdateClaim implements IReturn<ClaimDto>
{
    public id?: string;
    public claimSapId?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public mobile?: string;
    public phone?: string;
    public damageOccuredAt: string;
    public deadlineExtensionExpiresAt?: string;
    public backOfficeComment?: string;
    public estimationExpertComment?: string;
    public cause?: CauseDto;
    public perpetrator?: PerpetratorDto;
    public craftsmanIds?: string[];
    public craftsmanRatings?: { [index: string]: CraftsmanRatingDto; };
    public origin?: string;
    public suggestedBy: ClaimSuggestedBy;
    public suggestedByComment?: string;
    public customerType: CustomerType;
    public otherRole?: string;
    public company?: string;
    public parts?: PartDto[];
    public messageChannel: ClaimMessageChannel;
    public language?: string;
    public processingType: ProcessingType;
    public paymentInformation?: PaymentInformationDto;
    public contractNumber: number;
    public estimationExpertUsername?: string;

    public constructor(init?: Partial<UpdateClaim>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateClaim'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new ClaimDto(); }
}

// @Route("/claims/{Id}/contract", "PUT")
export class SetContract implements IReturn<ClaimDto>
{
    public id?: string;
    public contract?: ContractDto;

    public constructor(init?: Partial<SetContract>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetContract'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new ClaimDto(); }
}

// @Route("/claims/{ClaimId}", "PATCH")
export class PatchClaim implements IReturnVoid
{
    public claimId?: string;
    public assignedCraftsmanIds?: string[];
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public mobile?: string;
    public phone?: string;
    public email?: string;
    public customerType: CustomerType;
    public language?: string;
    public messageChannel: ClaimMessageChannel;
    public paymentInformation?: PaymentInformationDto;
    public deadlineExtensionExpiresAt: string;
    public isCancelled: boolean;
    public backOfficeComment?: string;
    public inClarificationByBackOfficeAssignee: boolean;
    public mediumProcessingType?: MediumProcessingType;
    public estimationExpertOpenIssueAction?: EstimationExpertOpenIssueActionDto;
    public inspections?: InspectionDto[];
    public inspectionNeeded: boolean;
    public caseHandlerRead: boolean;
    public hasNewDocument: boolean;

    public constructor(init?: Partial<PatchClaim>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchClaim'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/assignments", "PUT")
export class AssignClaim implements IReturn<ClaimAssignmentDto>
{
    public backOfficeUserId?: string;
    public estimationExpertUserId?: string;
    public claimId?: string;
    public estimationExpertAssignmentComment?: string;

    public constructor(init?: Partial<AssignClaim>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignClaim'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new ClaimAssignmentDto(); }
}

// @Route("/claims/{ClaimId}/assignedto", "DELETE")
export class UnassignBackOfficeFromClaim implements IReturnVoid
{
    public claimId?: string;

    public constructor(init?: Partial<UnassignBackOfficeFromClaim>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnassignBackOfficeFromClaim'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/snooze", "PUT")
export class SnoozeClaim implements IReturnVoid
{
    public claimId?: string;
    public reason?: string;
    public until: string;

    public constructor(init?: Partial<SnoozeClaim>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SnoozeClaim'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/snooze", "DELETE")
export class DeleteClaimSnooze implements IReturnVoid
{
    public claimId?: string;

    public constructor(init?: Partial<DeleteClaimSnooze>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteClaimSnooze'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/snooze-for-expert", "PUT")
export class SnoozeClaimForEstimationExpert implements IReturnVoid
{
    public claimId?: string;
    public comment?: string;
    public until: string;
    public disabled: boolean;
    public type: EstimationExpertSnoozeType;

    public constructor(init?: Partial<SnoozeClaimForEstimationExpert>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SnoozeClaimForEstimationExpert'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/snooze-for-expert", "DELETE")
export class DeleteClaimSnoozeForEstimationExpert implements IReturnVoid
{
    public claimId?: string;

    public constructor(init?: Partial<DeleteClaimSnoozeForEstimationExpert>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteClaimSnoozeForEstimationExpert'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/prejudice-checks", "POST")
export class CreatePrejudiceCheck implements IReturnVoid
{
    public claimId?: string;
    public passed: boolean;
    public reason?: string;

    public constructor(init?: Partial<CreatePrejudiceCheck>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreatePrejudiceCheck'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/back-office-closure", "POST,DELETE")
export class UpdateBackOfficeClosure implements IReturnVoid
{
    public claimId?: string;

    public constructor(init?: Partial<UpdateBackOfficeClosure>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateBackOfficeClosure'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/estimation-expert-closure", "POST")
export class UpdateEstimationExpertClosure implements IReturnVoid
{
    public claimId?: string;
    public costsCovered: boolean;
    public comment?: string;

    public constructor(init?: Partial<UpdateEstimationExpertClosure>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEstimationExpertClosure'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/estimation-expert-closure", "DELETE")
export class DeleteEstimationExpertClosure implements IReturnVoid
{
    public claimId?: string;

    public constructor(init?: Partial<DeleteEstimationExpertClosure>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteEstimationExpertClosure'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/claim-report-links/{Origin}/{Id}", "PATCH")
export class PatchClaimReportLink implements IReturn<ClaimReportLinkDto>
{
    public claimId?: string;
    public id?: string;
    public origin: ClaimReportOrigin;
    public hasReviewedDamageReporter: boolean;
    public hasReviewedAttachments: boolean;
    public hasReviewedParts: boolean;
    public hasReviewedIban: boolean;
    public hasReviewedProcessingType: boolean;

    public constructor(init?: Partial<PatchClaimReportLink>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchClaimReportLink'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new ClaimReportLinkDto(); }
}

// @Route("/claims/{ClaimId}/contract-change-request", "POST")
export class CreateContractChangeRequest implements IReturn<ContractChangeRequestDto>
{
    public claimId?: string;
    public note?: string;

    public constructor(init?: Partial<CreateContractChangeRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateContractChangeRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ContractChangeRequestDto(); }
}

// @Route("/claims/{ClaimId}/regress-audits", "POST")
export class StartRegressAudit implements IReturn<RegressAuditDto>
{
    public claimId?: string;
    public comment?: string;

    public constructor(init?: Partial<StartRegressAudit>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StartRegressAudit'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegressAuditDto(); }
}

// @Route("/claims/{ClaimId}/regress-audits", "PUT")
export class AddRegressActivity implements IReturnVoid
{
    public claimId?: string;
    public activity?: RegressActivityDto;

    public constructor(init?: Partial<AddRegressActivity>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddRegressActivity'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/regress-audits", "DELETE")
export class CancelRegressAudit implements IReturnVoid
{
    public claimId?: string;

    public constructor(init?: Partial<CancelRegressAudit>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancelRegressAudit'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/configs/color-mappings", "GET")
export class GetColorMappings implements IReturn<ColorMappingDto[]>
{

    public constructor(init?: Partial<GetColorMappings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetColorMappings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ColorMappingDto>(); }
}

// @Route("/contract-remediation-checks", "GET")
export class GetContractRemediationChecks extends PagedAndOrderedRequest implements IReturn<PagedResult<ContractRemediationCheckListDto>>
{
    /** @description Search query for filtering checks by .... */
    // @ApiMember(DataType="string", Description="Search query for filtering checks by ....", ParameterType="query", Verb="GET")
    public query?: string;

    /** @description Remediation Scope */
    // @ApiMember(DataType="string", Description="Remediation Scope", ParameterType="query", Verb="GET")
    public strainLevel?: StrainLevel;

    /** @description Remediation Scope */
    // @ApiMember(DataType="string", Description="Remediation Scope", ParameterType="query", Verb="GET")
    public contractNumber?: string;

    /** @description Filter by assigned user */
    // @ApiMember(DataType="string", Description="Filter by assigned user", ParameterType="query", Verb="GET")
    public assignedTo?: string[];

    /** @description Only unassigned */
    // @ApiMember(DataType="string", Description="Only unassigned", ParameterType="query", Verb="GET")
    public onlyUnassigned: boolean;

    /** @description is checked */
    // @ApiMember(DataType="string", Description="is checked", ParameterType="query", Verb="GET")
    public isChecked?: boolean;

    public constructor(init?: Partial<GetContractRemediationChecks>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContractRemediationChecks'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<ContractRemediationCheckListDto>(); }
}

// @Route("/contract-remediation-checks/{Id}/assignments", "PUT")
export class AssignContractRemediationCheck implements IReturn<ContractRemediationCheckDto>
{
    public userId?: string;
    public id?: string;

    public constructor(init?: Partial<AssignContractRemediationCheck>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignContractRemediationCheck'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new ContractRemediationCheckDto(); }
}

// @Route("/contract-remediation-checks/{Id}/result", "PUT")
export class CloseContractRemediationCheck implements IReturn<ContractRemediationCheckDto>
{
    public userId?: string;
    public id?: string;
    public decision: ContractRemediationDecision;
    public reason?: string;
    public fileName?: string;
    public fileContent?: string;
    public warningMessageContent?: string;
    public language?: string;

    public constructor(init?: Partial<CloseContractRemediationCheck>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CloseContractRemediationCheck'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new ContractRemediationCheckDto(); }
}

// @Route("/contract-remediation-checks/{Id}/assignments", "DELETE")
export class UnassignContractRemediationCheck implements IReturn<ContractRemediationCheckDto>
{
    public id?: string;

    public constructor(init?: Partial<UnassignContractRemediationCheck>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnassignContractRemediationCheck'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ContractRemediationCheckDto(); }
}

// @Route("/contract-remediation-check-summaries", "GET")
export class GetContractRemediationChecksSummary extends PagedAndOrderedRequest implements IReturn<PagedResult<ContractRemediationChecksSummaryDto>>
{
    public query?: string;

    public constructor(init?: Partial<GetContractRemediationChecksSummary>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContractRemediationChecksSummary'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<ContractRemediationChecksSummaryDto>(); }
}

// @Route("/contract-remediation-checks/{ContractRemediationCheckId}", "PATCH")
export class PatchContractRemediationCheck implements IReturnVoid
{
    public contractRemediationCheckId?: string;
    public strainLevel: StrainLevel;
    public damageRatio?: number;
    public claimCountSap?: number;

    public constructor(init?: Partial<PatchContractRemediationCheck>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchContractRemediationCheck'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() {}
}

// @Route("/contract-remediation-checks/{ContractRemediationCheckId}/attachment", "GET")
export class GetContractRemediationCheckAttachment implements IReturn<Blob>
{
    public contractRemediationCheckId?: string;

    public constructor(init?: Partial<GetContractRemediationCheckAttachment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContractRemediationCheckAttachment'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/contract-remediation-checks/statistics", "GET")
export class GetContractRemediationCheckStatistics implements IReturn<ContractRemediationCheckStatisticsDto>
{

    public constructor(init?: Partial<GetContractRemediationCheckStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContractRemediationCheckStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ContractRemediationCheckStatisticsDto(); }
}

// @Route("/claims/{Id}/contract", "PUT")
export class UpdateContract implements IReturn<ClaimDto>
{
    public id?: string;
    public contractNumber?: string;
    public damageOccuredAt: string;
    public d41?: D41Dto;
    public contract?: ContractDto;

    public constructor(init?: Partial<UpdateContract>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateContract'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new ClaimDto(); }
}

// @Route("/contracts", "GET")
export class GetContracts implements IReturn<ContractAddressDto[]>
{

    public constructor(init?: Partial<GetContracts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContracts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ContractAddressDto>(); }
}

// @Route("/customers", "GET")
export class GetCustomer implements IReturn<CustomerDto>
{

    public constructor(init?: Partial<GetCustomer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCustomer'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CustomerDto(); }
}

// @Route("/claims/{ClaimId}/craftsman-jobs", "POST")
export class CreateCraftsmanJob implements IReturn<CraftsmanJobDto>, IClaimAware
{
    public claimId?: string;
    public description?: string;
    public language?: string;

    public constructor(init?: Partial<CreateCraftsmanJob>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateCraftsmanJob'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CraftsmanJobDto(); }
}

// @Route("/jobs", "GET")
export class GetJobs extends PagedAndOrderedRequest implements IReturn<PagedResult<ClaimListDto>>
{
    public craftsmanId?: string;
    public status?: ClaimStatus;
    public query?: string;

    public constructor(init?: Partial<GetJobs>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetJobs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<ClaimListDto>(); }
}

// @Route("/craftsman-jobs/{Id}", "DELETE")
export class DeclineCraftsmanJob implements IReturnVoid
{
    public id?: string;
    public comment?: string;

    public constructor(init?: Partial<DeclineCraftsmanJob>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeclineCraftsmanJob'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/craftsman-jobs/{Id}/state", "PUT")
export class UpdateCraftsmanJobState implements IReturnVoid
{
    public id?: string;
    public state: CraftsmanJobState;

    public constructor(init?: Partial<UpdateCraftsmanJobState>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateCraftsmanJobState'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/craftsman-jobs/{Id}/appointment", "PUT")
export class UpdateCraftsmanJobAppointment implements IReturnVoid
{
    public id?: string;
    public appointment?: string;

    public constructor(init?: Partial<UpdateCraftsmanJobAppointment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateCraftsmanJobAppointment'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/craftsman-jobs/{Id}/fixingdate", "PUT")
export class UpdateFixingAt implements IReturnVoid
{
    public claimId?: string;
    public jobFixingAt: string;

    public constructor(init?: Partial<UpdateFixingAt>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateFixingAt'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/craftsman-jobs/{ClaimId}/fixed", "PUT")
export class UpdateFixedAt implements IReturnVoid
{
    public claimId?: string;
    public jobFixedAt: string;

    public constructor(init?: Partial<UpdateFixedAt>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateFixedAt'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/craftsman-jobs/{ClaimId}/closed", "PUT")
export class UpdateClosedAt implements IReturnVoid
{
    public claimId?: string;
    public jobClosedAt: string;
    public invoiceByLetterPost: boolean;

    public constructor(init?: Partial<UpdateClosedAt>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateClosedAt'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/craftsman-jobs/{ClaimId}/quote", "POST")
export class CreateQuote implements IReturnVoid, IClaimAware
{
    public claimId?: string;
    public price?: number;
    public description?: string;

    public constructor(init?: Partial<CreateQuote>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateQuote'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/craftsman-jobs/{ClaimId}/quote", "PUT")
export class UpdateQuoteState implements IReturnVoid, IClaimAware
{
    public claimId?: string;
    public accept: boolean;

    public constructor(init?: Partial<UpdateQuoteState>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateQuoteState'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/craftsmen", "GET")
export class GetCraftsmen extends PagedAndOrderedRequest implements IReturn<PagedResult<CraftsmanDto>>
{
    /** @description Search query for filtering craftsmen by .... */
    // @ApiMember(DataType="string", Description="Search query for filtering craftsmen by ....", Name="Query", ParameterType="query", Verb="GET")
    public query?: string;

    /** @description Search query for filtering craftsmen by email */
    // @ApiMember(DataType="string", Description="Search query for filtering craftsmen by email", ParameterType="query", Verb="GET")
    public email?: string;

    /** @description If provided results are ordered by distance to this point. Syntax: lat, long */
    // @ApiMember(DataType="string", Description="If provided results are ordered by distance to this point. Syntax: lat, long", ParameterType="query", Verb="GET")
    public center?: string;

    /** @description Set to true to wait for indexes to have all writes as of now applied */
    // @ApiMember(DataType="boolean", Description="Set to true to wait for indexes to have all writes as of now applied", ParameterType="query", Verb="GET")
    public waitForIndexing: boolean;

    public changedSince?: string;
    public includeDeleted: boolean;
    public includeInoperative: boolean;

    public constructor(init?: Partial<GetCraftsmen>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCraftsmen'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<CraftsmanDto>(); }
}

// @Route("/lookup/craftsmen", "GET")
export class LookupCraftsmen implements IReturn<CraftsmanLookupDto[]>
{
    public query?: string;

    public constructor(init?: Partial<LookupCraftsmen>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupCraftsmen'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CraftsmanLookupDto>(); }
}

// @Route("/craftsmen/{Id}", "GET")
export class GetCraftsman implements IReturn<CraftsmanDto>
{
    /** @description Craftman id */
    // @ApiMember(DataType="string", Description="Craftman id", IsRequired=true, Name="Id", ParameterType="path", Verb="GET")
    public id?: string;

    public constructor(init?: Partial<GetCraftsman>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCraftsman'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CraftsmanDto(); }
}

// @Route("/craftsmen/{Id}", "PUT")
// @Route("/craftsmen", "POST")
export class CreateOrUpdateCraftsman implements IReturn<CraftsmanDto>
{
    public id?: string;
    public company?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public phoneNumber?: string;
    public industries?: string[];
    public street?: string;
    public postalCode: number;
    public iban?: string;
    public place?: string;
    public comment?: string;
    public language?: string;
    public isInoperative: boolean;

    public constructor(init?: Partial<CreateOrUpdateCraftsman>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateOrUpdateCraftsman'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CraftsmanDto(); }
}

// @Route("/craftsmen/industries", "GET")
export class GetCraftsmanIndustries extends PagedRequest implements IReturn<PagedResult<string>>
{
    public query?: string;

    public constructor(init?: Partial<GetCraftsmanIndustries>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCraftsmanIndustries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<string>(); }
}

// @Route("/craftsmen/{Id}/usage", "GET")
export class GetCraftsmanUsage implements IReturn<CraftsmanUsageDto>
{
    public id?: string;

    public constructor(init?: Partial<GetCraftsmanUsage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCraftsmanUsage'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CraftsmanUsageDto(); }
}

// @Route("/craftsmen/{Id}", "DELETE")
export class DeleteCraftsman implements IReturnVoid
{
    public id?: string;

    public constructor(init?: Partial<DeleteCraftsman>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteCraftsman'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/craftsmen/language", "GET")
export class GetMyCraftsmanLanguage implements IReturn<string>
{

    public constructor(init?: Partial<GetMyCraftsmanLanguage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMyCraftsmanLanguage'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return ''; }
}

// @Route("/craftsmen/language", "PUT")
export class UpdateMyCraftsmanLanguage implements IReturnVoid
{
    public language?: string;

    public constructor(init?: Partial<UpdateMyCraftsmanLanguage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateMyCraftsmanLanguage'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/d41", "GET")
export class GetD41 implements IReturn<D41Dto>
{
    public claimId?: string;

    public constructor(init?: Partial<GetD41>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetD41'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new D41Dto(); }
}

// @Route("/claims/d41s", "POST")
export class GetD41sByChangeVectors extends PagedAndOrderedRequest implements IReturn<PagedResult<D41Dto>>, IPost
{
    public changeVectorsById?: { [index: string]: string; };

    public constructor(init?: Partial<GetD41sByChangeVectors>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetD41sByChangeVectors'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PagedResult<D41Dto>(); }
}

// @Route("/claims/{ClaimId}/d41")
export class SaveD41 implements IReturn<D41Dto>
{
    public claimId?: string;
    public d41?: D41Dto;
    public skipAutomaticSecondApproval: boolean;

    public constructor(init?: Partial<SaveD41>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveD41'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new D41Dto(); }
}

// @Route("/claims/{ClaimId}/d41/summary", "GET")
export class GetD41Summary implements IReturn<D41SummaryDto>
{
    public claimId?: string;

    public constructor(init?: Partial<GetD41Summary>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetD41Summary'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new D41SummaryDto(); }
}

// @Route("/claims/{ClaimId}/d41/cost-overview", "GET")
export class GetCostOverviewPdf implements IReturn<Blob>
{
    public claimId?: string;
    public inline: boolean;

    public constructor(init?: Partial<GetCostOverviewPdf>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCostOverviewPdf'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/claims/{ClaimId}/d41/payments/{PaymentId}/overview", "GET")
export class GetPaymentOverviewPdf implements IReturn<Blob>
{
    public claimId?: string;
    public paymentId: string;
    public inline: boolean;

    public constructor(init?: Partial<GetPaymentOverviewPdf>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPaymentOverviewPdf'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/claims/{ClaimId}/d41/benefits-overview", "GET")
export class GetBenefitsOverviewPdf implements IReturn<Blob>
{
    public claimId?: string;
    public inline: boolean;

    public constructor(init?: Partial<GetBenefitsOverviewPdf>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBenefitsOverviewPdf'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/claims/{ClaimId}/d41/payments/{PaymentId}", "PUT")
export class SendPaymentToSap implements IReturn<PaymentApprovalsUpdateDto>, IPut
{
    public claimId?: string;
    public paymentId: string;
    public skipAutomaticSecondApproval: boolean;

    public constructor(init?: Partial<SendPaymentToSap>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPaymentToSap'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new PaymentApprovalsUpdateDto(); }
}

// @Route("/claims/{ClaimId}/payments/{PaymentId}/second-approval", "PUT")
export class AssignSecondApprovalToMe implements IReturn<SecondApprovalAssignmentChangedDto>
{
    public claimId?: string;
    public paymentId: string;

    public constructor(init?: Partial<AssignSecondApprovalToMe>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignSecondApprovalToMe'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new SecondApprovalAssignmentChangedDto(); }
}

// @Route("/claims/{ClaimId}/payments/{PaymentId}/second-approval", "DELETE")
export class UnassignSecondApproval implements IReturnVoid
{
    public claimId?: string;
    public paymentId: string;

    public constructor(init?: Partial<UnassignSecondApproval>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnassignSecondApproval'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/d41/payments/{PaymentId}/second-approval", "POST")
export class CompleteSecondApproval implements IReturn<PaymentApprovalDto>
{
    public claimId?: string;
    public paymentId: string;

    public constructor(init?: Partial<CompleteSecondApproval>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CompleteSecondApproval'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PaymentApprovalDto(); }
}

// @Route("/claims/{ClaimId}/payments/{PaymentId}/first-approval", "DELETE")
export class RemoveFirstApproval implements IReturnVoid
{
    public claimId?: string;
    public paymentId: string;

    public constructor(init?: Partial<RemoveFirstApproval>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveFirstApproval'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/d41/payments/{PaymentId}/first-approval", "POST")
export class CompleteManualFirstApproval implements IReturn<PaymentApprovalDto>
{
    public claimId?: string;
    public paymentId: string;

    public constructor(init?: Partial<CompleteManualFirstApproval>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CompleteManualFirstApproval'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PaymentApprovalDto(); }
}

// @Route("/claims/{ClaimId}/d41/time-value-calculation", "GET")
export class GetTimeValueCalculationExcel implements IReturn<Blob>
{
    public claimId?: string;

    public constructor(init?: Partial<GetTimeValueCalculationExcel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTimeValueCalculationExcel'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/claims/{ClaimId}/damages-by-coverages", "PUT")
export class UpdateDamageByCoverages implements IReturnVoid
{
    public claimId?: string;
    public damageByCoverages?: DamageByCoverageDto[];

    public constructor(init?: Partial<UpdateDamageByCoverages>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateDamageByCoverages'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/documents/{Id}", "GET")
export class GetDocumentById implements IReturn<Blob>
{
    public id?: string;

    public constructor(init?: Partial<GetDocumentById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDocumentById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/inbox/emails", "GET")
export class GetEmailClaimReports extends PagedAndOrderedRequest implements IReturn<PagedResult<EmailClaimReportListItemDto>>
{
    /** @description Fulltext search for emails */
    // @ApiMember(DataType="string", Description="Fulltext search for emails", Name="Query", ParameterType="query", Verb="GET")
    public query?: string;

    /** @description Filter by claim report state */
    // @ApiMember(DataType="ClaimReportState", Description="Filter by claim report state", Name="State", ParameterType="query", Verb="GET")
    public state: ClaimReportState;

    /** @description Filter for UserId, that is assigned to the claimreport */
    // @ApiMember(DataType="string", Description="Filter for UserId, that is assigned to the claimreport", Name="AssignedTo", ParameterType="query", Verb="GET")
    public assignedTo?: string[];

    public constructor(init?: Partial<GetEmailClaimReports>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailClaimReports'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<EmailClaimReportListItemDto>(); }
}

// @Route("/inbox/emails/{Id}", "GET")
export class GetEmailClaimReport implements IReturn<EmailClaimReportDto>
{
    public id?: string;

    public constructor(init?: Partial<GetEmailClaimReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailClaimReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EmailClaimReportDto(); }
}

// @Route("/inbox/emails/{Id}", "PUT")
export class UpdateEmailClaimReport implements IReturnVoid
{
    public id?: string;
    public causeOfLoss?: string;
    public perpetrator?: PerpetratorDto;
    public role: CustomerType;
    public otherRole?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public repairWorkState: RepairWorkState;
    public iban?: string;
    public ibanOwnerName?: string;
    public assistanceFavored: boolean;
    public email?: string;
    public mobile?: string;
    public phone?: string;
    public contract?: ContractDto;
    public language?: string;
    public parts?: PartDto[];
    public damageOccuredAt?: string;

    public constructor(init?: Partial<UpdateEmailClaimReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEmailClaimReport'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/health", "GET")
export class GetHealthCheckResult implements IReturn<HealthCheckResult>
{

    public constructor(init?: Partial<GetHealthCheckResult>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetHealthCheckResult'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HealthCheckResult(); }
}

// @Route("/internal/generate-claim-attachments")
export class GenerateClaimAttachmentsInternal implements IReturnVoid, IPost
{
    public claimId?: string;
    public identity?: IdentityDto;

    public constructor(init?: Partial<GenerateClaimAttachmentsInternal>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GenerateClaimAttachmentsInternal'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/claims/{ClaimId}/messages", "GET")
export class GetMessagesForClaim extends PagedRequest implements IReturn<PagedResult<MessageDto>>, IClaimAware
{
    /** @description Get Message by ClaimId */
    // @ApiMember(DataType="long", Description="Get Message by ClaimId", IsRequired=true, Name="ClaimId", ParameterType="path", Verb="GET")
    public claimId?: string;

    public constructor(init?: Partial<GetMessagesForClaim>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMessagesForClaim'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<MessageDto>(); }
}

// @Route("/messages", "POST")
export class CreateMessage implements IReturn<MessageDto>, IClaimAware, IPost
{
    public claimId?: string;
    public correlationId?: string;
    public text?: string;
    public type?: MessageType;
    public attachments?: CreateAttachment[];
    public linkedAttachmentIds?: string[];
    public storeInternally: boolean;
    public senderFirstName?: string;
    public senderLastName?: string;
    public paymentId?: string;
    public notificationType: NotificationType;

    public constructor(init?: Partial<CreateMessage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateMessage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MessageDto(); }
}

// @Route("/messages/{MessageId}")
export class PatchMessage implements IReturnVoid, IClaimAware
{
    public messageId?: string;
    public claimId?: string;
    public isMessageRead?: boolean;

    public constructor(init?: Partial<PatchMessage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchMessage'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() {}
}

// @Route("/messages", "GET")
export class GetMessages extends PagedRequest implements IReturn<PagedResult<MessageDto>>
{
    public userId?: string;
    public changedSince?: string;
    public processingType?: ProcessingType;
    public excludeClosedByEstimationExpert: boolean;
    public waitForNonStaleResults: boolean;

    public constructor(init?: Partial<GetMessages>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMessages'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<MessageDto>(); }
}

// @Route("/messages/reminders")
export class CreateReminders implements IReturnVoid
{
    public claimIds?: string[];
    public reminderDeadlineExpiresAt: string;

    public constructor(init?: Partial<CreateReminders>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateReminders'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/mygvb/claims/{ClaimSapId}", "GET")
export class GetMyGvbClaim implements IReturn<MyGvbClaimDto>
{
    public claimSapId?: string;
    public language: MyGvbLanguage;

    public constructor(init?: Partial<GetMyGvbClaim>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMyGvbClaim'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MyGvbClaimDto(); }
}

// @Route("/mygvb/claims/contractpartners/{PartnerNumber}", "GET")
export class GetMyGvbClaims implements IReturn<MyGvbClaimDto[]>
{
    public partnerNumber: number;
    public language: MyGvbLanguage;

    public constructor(init?: Partial<GetMyGvbClaims>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMyGvbClaims'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<MyGvbClaimDto>(); }
}

// @Route("/claims/{ClaimId}/natural-hazards-prevention-notifications", "POST")
export class CreateNaturalHazardsPreventionNotification implements IReturn<NaturalHazardsPreventionNotificationDto>
{
    public claimId?: string;
    public generalInterest: boolean;
    public hailProtectionInterest: boolean;
    public centralBlindsControl: boolean;
    public origin: SmartClaimsApp;
    public note?: string;

    public constructor(init?: Partial<CreateNaturalHazardsPreventionNotification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateNaturalHazardsPreventionNotification'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NaturalHazardsPreventionNotificationDto(); }
}

// @Route("/notification-target", "PUT")
export class UpdateApplePushNotificationToken implements IReturnVoid
{
    public applePushNotificationToken?: string;

    public constructor(init?: Partial<UpdateApplePushNotificationToken>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateApplePushNotificationToken'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/inbox/calls", "GET")
export class GetPhoneCallClaimReports extends PagedAndOrderedRequest implements IReturn<PagedResult<PhoneCallClaimReportListItemDto>>
{
    public createdBy?: string[];
    public query?: string;
    public createdAt?: string;

    public constructor(init?: Partial<GetPhoneCallClaimReports>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPhoneCallClaimReports'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<PhoneCallClaimReportListItemDto>(); }
}

// @Route("/inbox/calls/{Id}", "GET")
export class GetPhoneCallClaimReport implements IReturn<PhoneCallClaimReportDto>
{
    /** @description Claim id */
    // @ApiMember(DataType="long", Description="Claim id", Name="CallId", ParameterType="path", Verb="GET")
    public id?: string;

    public constructor(init?: Partial<GetPhoneCallClaimReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPhoneCallClaimReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PhoneCallClaimReportDto(); }
}

// @Route("/inbox/calls/{Id}", "PUT")
export class UpdatePhoneCallClaimReport implements IReturn<PhoneCallClaimReportDto>
{
    public id?: string;
    public marked: boolean;
    public triageOnDraft: boolean;
    public messageChannel: ClaimMessageChannel;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public role: CustomerType;
    public otherRole?: string;
    public email?: string;
    public mobile?: string;
    public phone?: string;
    public language?: string;
    public iban?: string;
    public ibanOwnerName?: string;
    public preferredCraftsmanId?: string;
    public preferredCraftsmanSearch?: string;
    public parts?: PartDto[];
    public causeOfLoss?: string;
    public perpetrator?: PerpetratorDto;
    public repairWorkState: RepairWorkState;
    public assistanceFavored: boolean;
    public damageOccuredAt?: string;
    public contract?: ContractDto;
    public triageComment?: string;
    public cause?: CauseDto;
    public amountCovered?: number;
    public processingType?: ProcessingType;
    public estimationExpertUsername?: string;

    public constructor(init?: Partial<UpdatePhoneCallClaimReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatePhoneCallClaimReport'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new PhoneCallClaimReportDto(); }
}

// @Route("/inbox/calls", "POST")
export class CreatePhoneCallClaimReport implements IReturn<PhoneCallClaimReportDto>
{

    public constructor(init?: Partial<CreatePhoneCallClaimReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreatePhoneCallClaimReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PhoneCallClaimReportDto(); }
}

// @Route("/inbox/calls/{Id}/submit", "POST")
export class SubmitPhoneCallClaimReportForTriage implements IReturn<PhoneCallClaimReportDto>
{
    public id?: string;

    public constructor(init?: Partial<SubmitPhoneCallClaimReportForTriage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubmitPhoneCallClaimReportForTriage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PhoneCallClaimReportDto(); }
}

// @Route("/qr-bills/{QrBillId}", "GET")
export class GetQrBillPaymentSlip implements IReturn<Blob>
{
    public qrBillId?: string;
    public inline: boolean;
    public apiKey?: string;

    public constructor(init?: Partial<GetQrBillPaymentSlip>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetQrBillPaymentSlip'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/sap-proxy/contracts/{Id}", "GET")
export class GetSapContract implements IReturn<ContractDto>
{
    public id?: string;
    public date: string;

    public constructor(init?: Partial<GetSapContract>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSapContract'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ContractDto(); }
}

// @Route("/sap-proxy/contract-notes/{ClaimSapId}", "GET")
export class GetContractNotes implements IReturn<ContractNoteDto[]>
{
    public claimSapId?: string;

    public constructor(init?: Partial<GetContractNotes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContractNotes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ContractNoteDto>(); }
}

// @Route("/statistics/causes", "GET")
export class GetCauseStatistics implements IReturn<CauseCountDto[]>
{
    public interval: Interval;
    public from?: string;
    public to?: string;

    public constructor(init?: Partial<GetCauseStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCauseStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CauseCountDto>(); }
}

// @Route("/statistics/areas", "GET")
export class GetAreaStatistics implements IReturn<AreaCountDto[]>
{
    public placeCodes?: string[];

    public constructor(init?: Partial<GetAreaStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAreaStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AreaCountDto>(); }
}

// @Route("/statistics/claims", "GET")
export class GetClaimStatistics implements IReturn<ClaimStatisticsDto[]>
{
    public interval: Interval;
    public from?: string;
    public to?: string;

    public constructor(init?: Partial<GetClaimStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ClaimStatisticsDto>(); }
}

// @Route("/statistics/claims", "GET")
export class GetNonAggregatedClaimStatistics implements IReturn<NonAggregatedClaimStatisticsDto[]>
{
    public status?: ClaimStatus[];
    public isCancelled: boolean;
    public processingTypes?: ProcessingType[];
    public causeGroups?: string[];
    public from?: string;
    public to?: string;
    public areaNorthWest?: number[];
    public areaWidth: number;
    public areaHeight: number;

    public constructor(init?: Partial<GetNonAggregatedClaimStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetNonAggregatedClaimStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<NonAggregatedClaimStatisticsDto>(); }
}

// @Route("/statistics/claims/overview", "GET")
export class GetClaimOverviewStats implements IReturn<ClaimOverviewStatsDto>
{

    public constructor(init?: Partial<GetClaimOverviewStats>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaimOverviewStats'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClaimOverviewStatsDto(); }
}

// @Route("/statistics/claims/yearly", "GET")
export class GetYearlyClaimStatistics implements IReturn<ClaimStatisticsByYearDto[]>
{
    public year?: number;

    public constructor(init?: Partial<GetYearlyClaimStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetYearlyClaimStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ClaimStatisticsByYearDto>(); }
}

// @Route("/statistics/processing-types/unassigned", "GET")
export class GetUnassignedClaimCountByProcessingType implements IReturn<ProcessingTypeCountDto>
{

    public constructor(init?: Partial<GetUnassignedClaimCountByProcessingType>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUnassignedClaimCountByProcessingType'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcessingTypeCountDto(); }
}

// @Route("/statistics/estimation-experts/total-claims", "GET")
export class GetMediumClaimCountByEstimationExpert implements IReturn<EstimationExpertClaimCountDto[]>
{
    public userIds?: string[];

    public constructor(init?: Partial<GetMediumClaimCountByEstimationExpert>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMediumClaimCountByEstimationExpert'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<EstimationExpertClaimCountDto>(); }
}

// @Route("/web-form-claim-reports/attachments", "GET")
export class GetUnauthenticatedClaimAttachmentsByCorrelationId implements IReturn<ClaimAttachmentDto[]>
{
    public correlationId?: string;
    public type?: ClaimAttachmentType;

    public constructor(init?: Partial<GetUnauthenticatedClaimAttachmentsByCorrelationId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUnauthenticatedClaimAttachmentsByCorrelationId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ClaimAttachmentDto>(); }
}

// @Route("/web-form-claim-reports/attachments", "POST")
export class UploadWebFormClaimReportAttachmentByCorrelationId implements IReturn<ClaimAttachmentDto[]>
{
    public correlationId?: string;
    public type: ClaimAttachmentType;
    public timeToLiveInDays?: number;

    public constructor(init?: Partial<UploadWebFormClaimReportAttachmentByCorrelationId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadWebFormClaimReportAttachmentByCorrelationId'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<ClaimAttachmentDto>(); }
}

// @Route("/web-form-claim-reports/attachments/{Id}", "DELETE")
export class DeleteWebFormClaimReportAttachmentByCorrelationId implements IReturnVoid
{
    public id?: string;
    public correlationId?: string;

    public constructor(init?: Partial<DeleteWebFormClaimReportAttachmentByCorrelationId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteWebFormClaimReportAttachmentByCorrelationId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/claim-reports/{ClaimReportOrigin}/{ClaimReportId}/attachments", "POST")
export class UploadClaimReportAttachment implements IReturn<ClaimAttachmentDto[]>
{
    public claimReportId?: string;
    public claimReportOrigin: ClaimReportOrigin;
    public type: ClaimAttachmentType;
    public timeToLiveInDays?: number;

    public constructor(init?: Partial<UploadClaimReportAttachment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadClaimReportAttachment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<ClaimAttachmentDto>(); }
}

// @Route("/users", "GET")
export class GetUser implements IReturn<UserDto>
{
    public id?: string;
    public userName?: string;

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserDto(); }
}

// @Route("/users/{UserId}/badge", "GET")
export class GetUserBadge implements IReturn<Blob>
{
    public userId?: string;

    public constructor(init?: Partial<GetUserBadge>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserBadge'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/accounts/{UserId}/profile-picture", "GET")
export class GetProfilePicture implements IReturn<Blob>
{
    public userId?: string;
    public userName?: string;

    public constructor(init?: Partial<GetProfilePicture>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProfilePicture'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/users/{UserName}/profile-picture", "POST")
export class UploadProfilePicture implements IReturnVoid
{
    public userName?: string;
    public userId?: string;

    public constructor(init?: Partial<UploadProfilePicture>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadProfilePicture'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/users", "GET")
export class GetUsers extends PagedAndOrderedRequest implements IReturn<PagedResult<UserDto>>
{
    public usernames?: string[];
    public query?: string;
    public role?: string;
    public isEstimationExpert?: boolean;

    public constructor(init?: Partial<GetUsers>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUsers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PagedResult<UserDto>(); }
}

// @Route("/accounts", "POST")
export class CreateAccount implements IReturn<CreateAccountResponse>
{
    public firstName?: string;
    public lastName?: string;
    public userName?: string;
    public email?: string;
    public phoneNumber?: string;
    public role?: string;
    public permission?: string;
    public language?: string;
    public salutation?: string;

    public constructor(init?: Partial<CreateAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateAccount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateAccountResponse(); }
}

// @Route("/users/{Id}", "PUT")
export class UpdateUser implements IReturnVoid
{
    public id?: string;
    public firstName?: string;
    public lastName?: string;
    public phoneNumber?: string;
    public isEstimationExpert: boolean;
    public dontShowProfilePicture: boolean;
    public isSenior: boolean;

    public constructor(init?: Partial<UpdateUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateUser'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/craftsmen/{CraftsmanId}/accounts", "GET")
export class GetAccountsOfCraftsman implements IReturn<UserDto[]>
{
    public craftsmanId?: string;

    public constructor(init?: Partial<GetAccountsOfCraftsman>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAccountsOfCraftsman'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<UserDto>(); }
}

// @Route("/users/password-lost", "PUT")
export class PasswordLost implements IReturnVoid
{
    public email?: string;

    public constructor(init?: Partial<PasswordLost>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PasswordLost'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/users/send-invitation", "PUT")
export class ResendInvitation implements IReturnVoid
{
    public email?: string;
    public language?: string;

    public constructor(init?: Partial<ResendInvitation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ResendInvitation'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/users", "DELETE")
export class DeleteUser implements IReturnVoid
{
    public id: number;

    public constructor(init?: Partial<DeleteUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUser'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/weather/lightning/claims/{ClaimId}", "GET")
// @Route("/weather/lightning/claim-reports/{Origin}/{ClaimReportId}", "GET")
export class GetLightningExpertise implements IReturn<LightningExpertiseDto>
{
    public claimId?: string;
    public claimReportId?: string;
    public origin: ClaimReportOrigin;

    public constructor(init?: Partial<GetLightningExpertise>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLightningExpertise'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LightningExpertiseDto(); }
}

// @Route("/weather/lightning", "GET")
export class GetLightningExpertises implements IReturn<LightningExpertiseDto[]>
{
    public excludeClosedByEstimationExpert: boolean;
    public estimationExpertAssigneeId?: string;
    public changedSince?: string;

    public constructor(init?: Partial<GetLightningExpertises>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLightningExpertises'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<LightningExpertiseDto>(); }
}

// @Route("/weather/lightning/claims/{ClaimId}", "PUT")
// @Route("/weather/lightning/claim-reports/{Origin}/{ClaimReportId}", "PUT")
export class CheckForLightning implements IReturn<LightningExpertiseDto>
{
    public claimId?: string;
    public claimReportId?: string;
    public origin: ClaimReportOrigin;
    public startDate: string;
    public endDate: string;
    public latitude: number;
    public longitude: number;

    public constructor(init?: Partial<CheckForLightning>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckForLightning'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new LightningExpertiseDto(); }
}

// @Route("/weather/lightning/claims/{ClaimId}", "DELETE")
// @Route("/weather/lightning/claim-reports/{Origin}/{ClaimReportId}", "DELETE")
export class DeleteLightningExpertise implements IReturnVoid
{
    public claimId?: string;
    public claimReportId?: string;
    public origin: ClaimReportOrigin;

    public constructor(init?: Partial<DeleteLightningExpertise>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteLightningExpertise'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/internal/weather/lightning/{LightningExpertiseId}/pdf", "POST")
export class CreateLightningExpertisePdfInternal implements IReturnVoid, IPost
{
    public lightningExpertiseId?: string;

    public constructor(init?: Partial<CreateLightningExpertisePdfInternal>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateLightningExpertisePdfInternal'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/web-form-claim-reports/{CorrelationId}", "GET")
export class GetWebFormClaimReportByCorrelationId implements IReturn<WebFormClaimReportDto>
{
    public correlationId?: string;

    public constructor(init?: Partial<GetWebFormClaimReportByCorrelationId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWebFormClaimReportByCorrelationId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WebFormClaimReportDto(); }
}

// @Route("/web-form-claim-reports", "POST")
export class CreateWebFormClaimReport implements IReturn<WebFormClaimReportDto>
{
    public damageType: DamageType;
    public waterOrigin?: WaterOrigin;
    public pest?: Pest;
    public vandalismType?: VandalismType;
    public perpetrator?: PerpetratorDto;
    public causeOfLoss?: string;
    public damageOccuredAt?: string;
    public building?: BuildingDto;
    public contractPartner?: ContractPartnerDto;
    public reportedBy?: ReporterDto;

    public constructor(init?: Partial<CreateWebFormClaimReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateWebFormClaimReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WebFormClaimReportDto(); }
}

// @Route("/web-form-claim-reports/{CorrelationId}", "PUT")
export class UpdateWebFormClaimReportByCorrelationId implements IReturnVoid
{
    public correlationId?: string;
    public damageOccuredAt?: string;
    public damageType: DamageType;
    public waterOrigin?: WaterOrigin;
    public pest?: Pest;
    public vandalismType?: VandalismType;
    public perpetrator?: PerpetratorDto;
    public parts?: PartDto[];
    public causeOfLoss?: string;
    public building?: BuildingDto;
    public contractPartner?: ContractPartnerDto;
    public role: CustomerType;
    public otherRole?: string;
    public salutation: Salutation;
    public firstName?: string;
    public lastName?: string;
    public company?: string;
    public repairWorkState: RepairWorkState;
    public iban?: string;
    public ibanOwnerName?: string;
    public assistanceFavored: boolean;
    public email?: string;
    public emailVerification?: string;
    public mobile?: string;
    public phone?: string;
    public messageChannel: ClaimMessageChannel;
    public finished: boolean;
    public state: ClaimReportState;
    public createdAt: string;
    public preferredCraftsman?: CraftsmanLookupDto;
    public preferredCraftsmanSearch?: string;
    public language?: string;
    public reportedBy?: ReporterDto;

    public constructor(init?: Partial<UpdateWebFormClaimReportByCorrelationId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateWebFormClaimReportByCorrelationId'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/internal/process-webform-claim-report")
export class ProcessSubmittedWebFormClaimReportInternal implements IReturnVoid
{
    public webFormClaimReportId?: string;

    public constructor(init?: Partial<ProcessSubmittedWebFormClaimReportInternal>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcessSubmittedWebFormClaimReportInternal'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/working-types", "GET")
export class GetWorkingTypes implements IReturn<WorkingTypeDto[]>
{
    public query?: string;

    public constructor(init?: Partial<GetWorkingTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWorkingTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<WorkingTypeDto>(); }
}

export class ConvertTokenToCookie implements IReturnVoid
{

    public constructor(init?: Partial<ConvertTokenToCookie>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConvertTokenToCookie'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class DeleteTokenCookie implements IReturnVoid
{

    public constructor(init?: Partial<DeleteTokenCookie>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteTokenCookie'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/external-logout", "POST")
export class BackChannelLogoutRequest implements IReturnVoid
{
    // @DataMember(Name="logout_token")
    public logout_token?: string;

    public constructor(init?: Partial<BackChannelLogoutRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BackChannelLogoutRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/** @description Sign In */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /** @description AuthProvider, e.g. credentials */
    // @DataMember(Order=1)
    public provider?: string;

    // @DataMember(Order=2)
    public state?: string;

    // @DataMember(Order=3)
    public oauth_token?: string;

    // @DataMember(Order=4)
    public oauth_verifier?: string;

    // @DataMember(Order=5)
    public userName?: string;

    // @DataMember(Order=6)
    public password?: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView?: string;

    // @DataMember(Order=10)
    public nonce?: string;

    // @DataMember(Order=11)
    public uri?: string;

    // @DataMember(Order=12)
    public response?: string;

    // @DataMember(Order=13)
    public qop?: string;

    // @DataMember(Order=14)
    public nc?: string;

    // @DataMember(Order=15)
    public cnonce?: string;

    // @DataMember(Order=17)
    public accessToken?: string;

    // @DataMember(Order=18)
    public accessTokenSecret?: string;

    // @DataMember(Order=19)
    public scope?: string;

    // @DataMember(Order=20)
    public returnUrl?: string;

    // @DataMember(Order=21)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/webhooks/event-grid", "POST")
export class AzureEventGridWebhook extends Array<AzureEventGridEvent> implements IReturn<Object>
{

    public constructor(init?: Partial<AzureEventGridWebhook>) { super(); (Object as any).assign(this, init); }
    public getTypeName() { return 'AzureEventGridWebhook'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Object(); }
}

